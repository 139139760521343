import {
  // GET_AUTHORIZATIONS_REQUEST, GET_AUTHORIZATIONS_SUCCESS, GET_AUTHORIZATIONS_FAILURE,
  GET_AUTHORIZATIONS_SEARCH_FAILURE, GET_AUTHORIZATIONS_SEARCH_REQUEST, GET_AUTHORIZATIONS_SEARCH_SUCCESS,
  GET_AUTH_EXCEPTIONS_FAILURE, GET_AUTH_EXCEPTIONS_REQUEST, GET_AUTH_EXCEPTIONS_SUCCESS,
  GET_BUS_CODES_FAILURE, GET_BUS_CODES_REQUEST, GET_BUS_CODES_SUCCESS,
  GET_CLIENT_FIELDS_FAILURE, GET_CLIENT_FIELDS_REQUEST, GET_CLIENT_FIELDS_SUCCESS,
  GET_RECEIPT_ATTACHMENT_FAILURE, GET_RECEIPT_ATTACHMENT_REQUEST, GET_RECEIPT_ATTACHMENT_SUCCESS,
  GET_REPORTS_FAILURE, GET_REPORTS_REQUEST, GET_REPORTS_SUCCESS,
  GET_SEARCH_COMMENTS_FAILURE, GET_SEARCH_COMMENTS_REQUEST, GET_SEARCH_COMMENTS_SUCCESS,
  GET_SUBMITTED_AUTHORIZATION_FAILURE, GET_SUBMITTED_AUTHORIZATION_REQUEST, GET_SUBMITTED_AUTHORIZATION_SUCCESS,
  GET_TIMELINE_ITEMS_FAILURE, GET_TIMELINE_ITEMS_REQUEST, GET_TIMELINE_ITEMS_SUCCESS,
  SET_ACTIVE_TRANSFEREE_TAB, SET_BASE_MOVE_INFO
} from './transferees.actions';
import Immutable from 'immutable';
import {
  mapStatusCategories
} from './utils';

const initialState = Immutable.Map({
  clientFields: [],
  // authorizations: [],
  authorizationSearchResults: [],
  isLoading: false,
  isLoadingReceiptImage: false,
  timelineItems: [],
  reports: [],
  receiptAttachmentImages: {},
  activeTransfereeTab: 0,
  baseMoveInfo: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    // case GET_AUTHORIZATIONS_REQUEST:
    //   return state.set('isLoading', true);

    // case GET_AUTHORIZATIONS_SUCCESS:
    //   return state.set('authorizations', action.response).set('isLoading', false);

    // case GET_AUTHORIZATIONS_FAILURE:
    //   return state.set('isLoading', false);

    case GET_AUTHORIZATIONS_SEARCH_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTHORIZATIONS_SEARCH_SUCCESS:
      return state.set('authorizationSearchResults', mapStatusCategories(action.response)).set('isLoading', false);

    case GET_AUTHORIZATIONS_SEARCH_FAILURE:
      return state.set('isLoading', false);

    case GET_CLIENT_FIELDS_REQUEST:
      return state.set('isLoading', true);

    case GET_CLIENT_FIELDS_SUCCESS:
      return state.set('clientFields', action.response).set('isLoading', false);

    case GET_CLIENT_FIELDS_FAILURE:
      return state.set('isLoading', false);

    case GET_BUS_CODES_REQUEST:
      return state.set('isLoading', true);

    case GET_BUS_CODES_SUCCESS:
      return state.set('busCodes', action.response).set('isLoading', false);

    case GET_BUS_CODES_FAILURE:
      return state.set('isLoading', false);

    case GET_SUBMITTED_AUTHORIZATION_REQUEST:
      return state.set('isLoading', true);

    case GET_SUBMITTED_AUTHORIZATION_SUCCESS:
      return state.set('submittedAuthDetail', action.response).set('isLoading', false);

    case GET_SUBMITTED_AUTHORIZATION_FAILURE:
      return state.set('submittedAuthDetail', null).set('isLoading', false);

    case GET_AUTH_EXCEPTIONS_REQUEST:
      return state.set('isLoading', true);

    case GET_AUTH_EXCEPTIONS_SUCCESS:
      return state.set('submittedAuthExceptions', action.response).set('isLoading', false);

    case GET_AUTH_EXCEPTIONS_FAILURE:
      return state.set('isLoading', false);

    case GET_SEARCH_COMMENTS_REQUEST:
      return state.set('isLoading', true);

    case GET_SEARCH_COMMENTS_SUCCESS:
      return state.set('searchComments', action.response).set('isLoading', false);

    case GET_SEARCH_COMMENTS_FAILURE:
      return state.set('isLoading', false);

    case GET_TIMELINE_ITEMS_REQUEST:
      return state.set('isLoading', true);

    case GET_TIMELINE_ITEMS_SUCCESS:
      return state.set('timelineItems', action.response).set('isLoading', false);

    case GET_TIMELINE_ITEMS_FAILURE:
      return state.set('isLoading', false);

    case GET_REPORTS_REQUEST:
      return state.set('isLoading', true);

    case GET_REPORTS_SUCCESS:
      return state.set('reports', action.response).set('isLoading', false);

    case GET_REPORTS_FAILURE:
      return state.set('isLoading', false);

    case GET_RECEIPT_ATTACHMENT_REQUEST:
      return state.set('isLoadingReceiptImage', true);

    case GET_RECEIPT_ATTACHMENT_SUCCESS:
      const images = state.get('receiptAttachmentImages');
      images[action.response.AttachmentId] = action.response;
      return state.set('receiptAttachmentImages', images)
        .set('isLoadingReceiptImage', false);

    case GET_RECEIPT_ATTACHMENT_FAILURE:
      return state.set('isLoadingReceiptImage', false);

    case SET_ACTIVE_TRANSFEREE_TAB:
      return state.set('activeTransfereeTab', action.payload);

    case SET_BASE_MOVE_INFO:
      return state.set('baseMoveInfo', action.payload);

    default:
      return state;
  }
}
