import HhgImage from './images/moving-truck.jpg';
import TempLivingImage from './images/temporary-living.jpg';
import FinalMoveImage from './images/airplane-travel.jpg';
import HomeFindingImage from './images/home-finding.jpeg';
import HomePurchasingImage from './images/home-purchasing.jpg';
import HomeSaleImage from './images/home-sale.jpg';
import RentalImage from './images/rental-image.jpg';
import SpousalImage from './images/spousal-assistance.jpg';
import GeneralRelocationImage from './images/general-relocation.jpg';

export const benefits = [
    {
        name: 'Household Goods',
        secondaryText: 'Shipment of your household goods, vehicles, and pets.',
        imageUrl: HhgImage,
        id: 0,
        isSelected: false,
        options: [
            {
                description: 'Full pack, load, transport.',
                variable: 'miles',
                multiplier: 25,
                optionId: 1
            },
            {
                description: 'Auto Shipment.',
                variable: 'miles',
                multiplier: 15,
                optionId: 2
            },
            // {
            //     description: 'Partial unpack.',
            //     cost: 100,
            //     optionId: 3
            // },
            // {
            //     description: 'Normal appliance servicing.',
            //     cost: 100,
            //     optionId: 4
            // },
            // {
            //     description: 'Crating.',
            //     cost: 100,
            //     optionId: 5
            // },
            {
                description: 'Storage.',
                cost: 500,
                optionId: 6
            },
            // {
            //     description: 'Insurance.',
            //     cost: 100,
            //     optionId: 7
            // },
            // {
            //     description: 'Discard and donate.',
            //     cost: 0,
            //     optionId: 8
            // },
        ]
    },
    {
        name: 'Rental',
        secondaryText: 'Costs associated with a rental relocation.',
        imageUrl: RentalImage,
        id: 1,
        isSelected: false,
        isRenterOnly: true,
        options: [
            {
                description: 'Rental tour fees (assuming $995 per day).',
                cost: 995,
                optionId: 9
            },
            {
                description: 'Lease cancellation.',
                cost: 1500,
                optionId: 10
            },
        ]
    },
    {
        name: 'Temporary Living',
        secondaryText: 'Temporary housing in your new location.',
        imageUrl: TempLivingImage,
        id: 2,
        isSelected: false,
        options: [
            {
                description: 'Days in temp living.',
                cost: 6000,
                optionId: 11,
                selections: [{
                  selectionId: 1,
                  text: 'Up to 30 days.',
                  cost: 3000
                }, {
                  selectionId: 2,
                  text: 'Up to 60 days.',
                  cost: 6000
                }, {
                  selectionId: 3,
                  text: 'Up to 90 days.',
                  cost: 9000
                }]
            },
            {
                description: '2 trips home per month.',
                cost: 1500,
                optionId: 12
            },
            {
                description: 'Rental car for 10 days or until personal car is delivered.',
                cost: 300,
                optionId: 13
            },
        ]
    },
    {
        name: 'Home Finding',
        secondaryText: 'Support for new home finding trips.',
        imageUrl: HomeFindingImage,
        id: 3,
        isSelected: false,
        options: [
            {
                description: 'Airfare / mileage / tolls.',
                variable: 'miles',
                multiplier: 12,
                optionId: 14
            },
            {
                description: 'Lodging.',
                cost: 500,
                optionId: 15
            },
            {
                description: 'Meal per diem.',
                cost: 300,
                optionId: 16
            },
            {
                description: 'Car rental.',
                cost: 300,
                optionId: 17
            },
            {
                description: 'Transportation to/from airport or airport parking if applicable.',
                cost: 100,
                optionId: 18
            },
        ]
    },
    {
        name: 'Home Purchasing',
        secondaryText: 'Support for new home purchasing.',
        imageUrl: HomePurchasingImage,
        id: 4,
        isSelected: false,
        isOwnerOnly: true,
        options: [
            {
                description: 'Direct bill / reimbursement on 2% of mortgage.',
                cost: 3250,
                optionId: 19
            },
            {
                description: 'Referrals to preferred agents.',
                cost: 0,
                optionId: 20
            },
        ]
    },
    {
        name: 'Home Sale',
        secondaryText: 'Assistance with home sale.',
        imageUrl: HomeSaleImage,
        id: 5,
        isSelected: false,
        isOwnerOnly: true,
        options: [
            {
                description: 'Direct reimbursement - typically calculated as 7% of the sale price as closing costs.',
                cost: 2500,
                optionId: 21
            },
        ]
    },
    {
        name: 'Spouse / Partner Assistance',
        secondaryText: 'Assistance for the spouse / partner in the new location.',
        imageUrl: SpousalImage,
        id: 6,
        isSelected: false,
        options: [
            {
                description: 'Courses, professional liscensing, resume support, etc.',
                cost: 500,
                optionId: 22
            },
        ]
    },
    {
        name: 'Relocation Allowance',
        secondaryText: 'Custom allowance for general relocation costs.',
        imageUrl: GeneralRelocationImage,
        id: 8,
        isSelected: false,
        customTotal: true,
        options: [
            {
                description: 'Allowance for miscellaneous benefits.',
                customTotal: true,
                optionId: 27
            },
        ]
    },
    {
        name: 'Final Move',
        secondaryText: 'Costs associated with the final trip.',
        imageUrl: FinalMoveImage,
        id: 7,
        isSelected: false,
        options: [
            {
                description: 'Airfare, mileage, tolls, etc.',
                variable: 'miles',
                multiplier: 12,
                optionId: 23
            },
            {
                description: 'Lodging Reimbursement.',
                cost: 250,
                optionId: 24,
                selections: [{
                    selectionId: 4,
                    text: '1 Night.',
                    cost: 125
                }, {
                    selectionId: 5,
                    text: '2 Nights.',
                    cost: 250
                }, {
                    selectionId: 6,
                    text: '3 Nights.',
                    cost: 375
                }, {
                    selectionId: 7,
                    text: '4 Nights.',
                    cost: 500
                }]
            },
            {
                description: 'Meal per diem.',
                cost: 300,
                optionId: 25
            },
            {
                description: 'Transportation to/from airport.',
                cost: 100,
                optionId: 26
            },
        ]
    },
]