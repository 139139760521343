import { CALL_API } from '../../middleware/api';

// export const GET_AUTHORIZATIONS_REQUEST = 'GET_AUTHORIZATIONS_REQUEST';
// export const GET_AUTHORIZATIONS_SUCCESS = 'GET_AUTHORIZATIONS_SUCCESS';
// export const GET_AUTHORIZATIONS_FAILURE = 'GET_AUTHORIZATIONS_FAILURE';

export const GET_AUTHORIZATIONS_SEARCH_REQUEST = 'GET_AUTHORIZATIONS_SEARCH_REQUEST';
export const GET_AUTHORIZATIONS_SEARCH_SUCCESS = 'GET_AUTHORIZATIONS_SEARCH_SUCCESS';
export const GET_AUTHORIZATIONS_SEARCH_FAILURE = 'GET_AUTHORIZATIONS_SEARCH_FAILURE';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const GET_CLIENT_FIELDS_REQUEST = 'GET_CLIENT_FIELDS_REQUEST';
export const GET_CLIENT_FIELDS_SUCCESS = 'GET_CLIENT_FIELDS_SUCCESS';
export const GET_CLIENT_FIELDS_FAILURE = 'GET_CLIENT_FIELDS_FAILURE';

export const GET_BUS_CODES_REQUEST = 'GET_BUS_CODES_REQUEST';
export const GET_BUS_CODES_SUCCESS = 'GET_BUS_CODES_SUCCESS';
export const GET_BUS_CODES_FAILURE = 'GET_BUS_CODES_FAILURE';

export const GET_SUBMITTED_AUTHORIZATION_REQUEST = 'GET_SUBMITTED_AUTHORIZATION_REQUEST';
export const GET_SUBMITTED_AUTHORIZATION_SUCCESS = 'GET_SUBMITTED_AUTHORIZATION_SUCCESS';
export const GET_SUBMITTED_AUTHORIZATION_FAILURE = 'GET_SUBMITTED_AUTHORIZATION_FAILURE';

export const GET_AUTH_EXCEPTIONS_REQUEST = 'GET_AUTH_EXCEPTIONS_REQUEST';
export const GET_AUTH_EXCEPTIONS_SUCCESS = 'GET_AUTH_EXCEPTIONS_SUCCESS';
export const GET_AUTH_EXCEPTIONS_FAILURE = 'GET_AUTH_EXCEPTIONS_FAILURE';

export const GET_SEARCH_COMMENTS_REQUEST = 'GET_SEARCH_COMMENTS_REQUEST';
export const GET_SEARCH_COMMENTS_SUCCESS = 'GET_SEARCH_COMMENTS_SUCCESS';
export const GET_SEARCH_COMMENTS_FAILURE = 'GET_SEARCH_COMMENTS_FAILURE';

export const GET_TIMELINE_ITEMS_REQUEST = 'GET_TIMELINE_ITEMS_REQUEST';
export const GET_TIMELINE_ITEMS_SUCCESS = 'GET_TIMELINE_ITEMS_SUCCESS';
export const GET_TIMELINE_ITEMS_FAILURE = 'GET_TIMELINE_ITEMS_FAILURE';

export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';

export const GET_RECEIPT_ATTACHMENT_REQUEST = 'GET_RECEIPT_ATTACHMENT_REQUEST';
export const GET_RECEIPT_ATTACHMENT_SUCCESS = 'GET_RECEIPT_ATTACHMENT_SUCCESS';
export const GET_RECEIPT_ATTACHMENT_FAILURE = 'GET_RECEIPT_ATTACHMENT_FAILURE';

export const SET_ACTIVE_TRANSFEREE_TAB = 'SET_ACTIVE_TRANSFEREE_TAB';

export const SET_BASE_MOVE_INFO = 'SET_BASE_MOVE_INFO';

export const GET_SIMILAR_TRANSFEREE_SUMMARIES_REQUEST = 'GET_SIMILAR_TRANSFEREE_SUMMARIES_REQUEST';
export const GET_SIMILAR_TRANSFEREE_SUMMARIES_SUCCESS = 'GET_SIMILAR_TRANSFEREE_SUMMARIES_SUCCESS';
export const GET_SIMILAR_TRANSFEREE_SUMMARIES_FAILURE = 'GET_SIMILAR_TRANSFEREE_SUMMARIES_FAILURE';

// export const getAuthorizations = () => {
//   return {
//     [CALL_API]: {
//       types: [GET_AUTHORIZATIONS_REQUEST, GET_AUTHORIZATIONS_SUCCESS, GET_AUTHORIZATIONS_FAILURE],
//       authenticated: true,
//       endpoint: `Authorization/GetAuthorizationsSubmitted`,
//       method: 'GET'
//     }
//   };
// };

export const getAuthorizationSearchResults = () => {
  return {
    [CALL_API]: {
      types: [GET_AUTHORIZATIONS_SEARCH_REQUEST, GET_AUTHORIZATIONS_SEARCH_SUCCESS, GET_AUTHORIZATIONS_SEARCH_FAILURE],
      authenticated: true,
      endpoint: 'AuthorizationFile/GetAuthorizationSearchResults',
      method: 'POST',
      payload: {},
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getClientSpecificFields = (payload) => {
  return {
    [CALL_API]: {
      types: [GET_CLIENT_FIELDS_REQUEST, GET_CLIENT_FIELDS_SUCCESS, GET_CLIENT_FIELDS_FAILURE],
      authenticated: true,
      endpoint: 'AuthorizationFile/GetClientSpecificFields',
      method: 'POST',
      payload,
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getBusCodes = () => {
  return {
    [CALL_API]: {
      types: [GET_BUS_CODES_REQUEST, GET_BUS_CODES_SUCCESS, GET_BUS_CODES_FAILURE],
      authenticated: true,
      endpoint: 'Resource/GetBusCodes',
      method: 'GET',
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getSubmittedAuthorization = (id) => {
  return {
    [CALL_API]: {
      types: [GET_SUBMITTED_AUTHORIZATION_REQUEST, GET_SUBMITTED_AUTHORIZATION_SUCCESS, GET_SUBMITTED_AUTHORIZATION_FAILURE],
      authenticated: true,
      endpoint: 'AuthorizationFile/GetAuthorizationFile',
      method: 'POST',
      payload: { AuthorizationID: id },
      options: {
        isClientSpecific: true,
        isDivisionSpecific: true,
      },
    },
  };
};

export const getExceptionsByAuthorizationId = (id) => {
  return {
    [CALL_API]: {
      types: [GET_AUTH_EXCEPTIONS_REQUEST, GET_AUTH_EXCEPTIONS_SUCCESS, GET_AUTH_EXCEPTIONS_FAILURE],
      authenticated: true,
      endpoint: 'Exception/GetExceptionsByAuthorizationId',
      method: 'POST',
      payload: { AuthorizationID: id },
    },
  };
};

export const getSearchCommentResults = (id) => {
  return {
    [CALL_API]: {
      types: [GET_SEARCH_COMMENTS_REQUEST, GET_SEARCH_COMMENTS_SUCCESS, GET_SEARCH_COMMENTS_FAILURE],
      authenticated: true,
      endpoint: 'AuthorizationFile/GetSearchCommentResults',
      method: 'POST',
      payload: { AuthorizationID: id },
    },
  };
};

export const getTimelineItems = (id) => {
  return {
    [CALL_API]: {
      types: [GET_TIMELINE_ITEMS_REQUEST, GET_TIMELINE_ITEMS_SUCCESS, GET_TIMELINE_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `Transferee/GetTransfereeTimelineCards?authorizationId=${id}`,
      method: 'GET',
    },
  };
};

export const getReports = (id) => {
  return {
    [CALL_API]: {
      types: [GET_REPORTS_REQUEST, GET_REPORTS_SUCCESS, GET_REPORTS_FAILURE],
      authenticated: true,
      endpoint: `Expense/GetReportsForClientAuthorization?authorizationID=${id}`,
      method: 'GET',
    },
  };
};

export const getReceiptAttachment = (attachmentId) => {
  return {
    [CALL_API]: {
      types: [GET_RECEIPT_ATTACHMENT_REQUEST, GET_RECEIPT_ATTACHMENT_SUCCESS, GET_RECEIPT_ATTACHMENT_FAILURE],
      authenticated: true,
      endpoint: `Expense/GetReceiptAttachment?attachmentId=${attachmentId}`,
      method: 'GET',
    },
  };
};

export const getSimilarTransfereeSummaries = (body) => {
  return {
    [CALL_API]: {
      types: [GET_SIMILAR_TRANSFEREE_SUMMARIES_REQUEST, GET_SIMILAR_TRANSFEREE_SUMMARIES_SUCCESS, GET_SIMILAR_TRANSFEREE_SUMMARIES_FAILURE],
      authenticated: true,
      endpoint: 'AuthorizationFile/GetSimilarTransfereeSummaries',
      method: 'POST',
      payload: body,
    },
  };
};

export const setActiveTransfereeTab = (index) => {
  return {
    type: SET_ACTIVE_TRANSFEREE_TAB,
    payload: index,
  };
};

export const setBaseMoveInfo = (body) => {
  return {
    type: SET_BASE_MOVE_INFO,
    payload: body,
  };
};
