import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment, useState } from 'react';
// import Truncate from 'react-truncate';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CostUnit, displayCost } from '../../utilities/currencyFormatter';
import LogoSpinner from '../../common/logoSpinner.component';
import SelectionsCostBar from './selectionsCostBar.component';

import { setEstimations } from './estimator.actions';

import CloseIcon from '@material-ui/icons/Close';
import { benefits } from './constants';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 80,
    marginRight: '5px'
  },
  longButton: {
    width: 125
  },
  moveGuideHeader:{
    height: '75px',
    borderBottom: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    color: '#7F7F7F',
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
  },
  progressBar: {
    width: '100%',
  },
  optionRow: {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.palette.divider,
    minHeight: theme.spacing(3),
  },
  benefitImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '60px',
  },
  benefitImageSkipped: {
    filter: 'grayscale(100%)',
  },
  optionQuantityText:{
    fontStyle: 'italic',
    fontSize: '0.6rem',
  },
  benefitListContainer: {
    overflow: 'auto',
  },
  benefitListWrapper: {
    overflow: 'auto',
  },
  loadingOverlay :{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 3,
    backgroundColor: 'rgba(0,0,0,0.3)', // controls black overlay opacity
    display: 'flex',
  },
  benefitSelected: {
    boxShadow: '0px 0px 12px 1px rgba(90,154,156,0.5)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    maxWidth: '200px',
  },
}));



const BenefitSelections = (props) => {
  const { isUpdatingSelections } = props;
  const [expandedBenefitId, setExpandedBenefitId] = useState(null);
  const [savedBenefits, setSavedBenefits] = useState(props.estimateInfo && props.estimateInfo.savedBenefits ? props.estimateInfo.savedBenefits : []);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [selectedBenefitOptions, setSelectedBenefitOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [applyTaxGrossUp, setApplyTaxGrossUp] = useState(props.estimateInfo && props.estimateInfo.applyTaxGrossUp ? true : false);

  const [customTotal, setCustomTotal] = useState((props.estimateInfo && props.estimateInfo.savedBenefits && props.estimateInfo.savedBenefits.find((sb) => sb.id === 8)) ? props.estimateInfo.savedBenefits.find((sb) => sb.id === 8).cost : 0);
  const [customTotalIsTaxable, setCustomTotalIsTaxable] = useState((props.estimateInfo && props.estimateInfo.savedBenefits && props.estimateInfo.savedBenefits.find((sb) => sb.id === 8)) ? props.estimateInfo.savedBenefits.find((sb) => sb.id === 8).isTaxable : false);

  const classes = useStyles();

  const setBenOptionSelection = (checked, opt) => {
    let tempSelectedBenefitOptions = [...selectedBenefitOptions];
    if (checked) {
      tempSelectedBenefitOptions.push({ optionId: opt.optionId, selectionId: opt.selections ? opt.selections[0].selectionId : undefined});
    } else {
      tempSelectedBenefitOptions = tempSelectedBenefitOptions.filter((tsb) => tsb.optionId !== opt.optionId);
    }
    setSelectedBenefitOptions(tempSelectedBenefitOptions);
  }

  const setBenOptionDropdown = (opt, val) => {
    let tempSelectedBenefitOptions = [...selectedBenefitOptions];
    let optIndex = tempSelectedBenefitOptions.findIndex((tsbo) => tsbo.optionId === opt.optionId);
    if (optIndex != null) {
      tempSelectedBenefitOptions[optIndex].selectionId = val;
    } else {
      tempSelectedBenefitOptions.push({ optionId: opt.optionId, selectionId: val});
    }
    setSelectedBenefitOptions(tempSelectedBenefitOptions);
  }

  const getOptionCost = (opt, selectedOption) => {
    if (!opt) return 0;

    // const fullOptionInfo = selectedBenefit.options.find((sbo) => sbo.optionId === opt.optionId);
    if (opt.variable) {
      return props.distance * opt.multiplier;
    } else if (selectedOption && selectedOption.selectionId) {
      return opt.selections.find((selection) => selection.selectionId === selectedOption.selectionId).cost;
    } else {
      return opt.cost;
    }
  }

  const enableBenefit = () => {
    setIsLoading(true);

    setTimeout(() => {}, 2000);

    let tempSavedBenefits = [...savedBenefits];
    if (!selectedBenefit.customTotal) {
      tempSavedBenefits.push({ id: selectedBenefit.id, options: selectedBenefitOptions});
    } else {
      tempSavedBenefits.push({ id: selectedBenefit.id, options: selectedBenefitOptions, cost: Number(customTotal), isTaxable: customTotalIsTaxable});
    }
    setSavedBenefits(tempSavedBenefits);

    setIsLoading(false);
    setShowUpdateModal(false);
  }

  const updateBenefit = () => {
    setIsLoading(true);

    let tempSavedBenefits = [...savedBenefits];
    let tempSavedBenefitIndex = tempSavedBenefits.findIndex((tsb) => tsb.id === selectedBenefit.id);
    if (!selectedBenefit.customTotal) {
      tempSavedBenefits[tempSavedBenefitIndex] = {id: selectedBenefit.id, options: selectedBenefitOptions};
    } else {
      tempSavedBenefits[tempSavedBenefitIndex] = {id: selectedBenefit.id, options: selectedBenefitOptions, cost: Number(customTotal), isTaxable: customTotalIsTaxable};
    }

    setSavedBenefits(tempSavedBenefits);

    setIsLoading(false);
    setShowUpdateModal(false);
  }

  const disableBenefit = () => {
    setIsLoading(true);

    let tempSavedBenefits = [...savedBenefits];
    tempSavedBenefits = tempSavedBenefits.filter((tsb) => selectedBenefit.id !== tsb.id);
    setSavedBenefits(tempSavedBenefits);

    setIsLoading(false);
    setShowUpdateModal(false);
  }

  const handleAccordionChange = (benefitId) => (event, isExpanded) => {
    setExpandedBenefitId(isExpanded ? benefitId : false);
  };

  const renderOption = (option, benefit, benIsSelected, optIsSelected) => {
    let optionText = '';
    let savedOption = savedBenefits.find((sb) => sb.id === benefit.id) ? savedBenefits.find((sb) => sb.id === benefit.id).options.find((sbo) => sbo.optionId === option.optionId) : null;
    
    if (option.variable) {
      optionText = `${option.description} (${props.distance} Miles * $${option.multiplier})`;
    } else if (option.selections && savedOption) {
      optionText = savedOption ? `${option.description} (${option.selections.find((os) => os.selectionId === savedOption.selectionId).text})` : option.description;
    } else {
      optionText = option.description;
    }

    return (
      <Grid item container direction="row" className={classes.optionRow}>
        <Grid item xs={8} container direction="column">
          <Grid item>
            <Typography variant="body2">
              {optionText}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={2} justify="flex-end" alignContent="center">
          {!benefit.customTotal && (
            <Typography variant="caption">{displayCost(getOptionCost(option, savedOption), CostUnit.CURRENCY)}</Typography>
          )}
          {benefit.customTotal && (
            <Typography variant="caption">{displayCost(savedBenefits.find((sb) => sb.id === benefit.id) ? Number(savedBenefits.find((sb) => sb.id === benefit.id).cost) : 0, CostUnit.CURRENCY)}</Typography>
          )}
        </Grid>
        <Grid item container xs={2} justify="flex-start" alignContent="center">
          {benIsSelected && optIsSelected && 
            <Box pl={2}>
              <Typography variant="caption" color="secondary"><FontAwesomeIcon icon="check-circle" /> Confirmed</Typography>
            </Box>
          }
          {benIsSelected && !optIsSelected && 
            <Box pl={2}>
              <Typography variant="caption" color="error"><FontAwesomeIcon icon="times-circle" /> Declined</Typography>
            </Box>
          }
        </Grid>
      </Grid>
    )
  };

  const renderBenefitRowAction = (benefit, savedBenefit) => {
    if (savedBenefit) {
        return (
                <Button size="small" variant="outlined" disabled={false} color="primary" onClick={(e) => { setSelectedBenefit(benefit); setSelectedBenefitOptions(savedBenefit.options); setShowUpdateModal(true); }}>
                    Update
                </Button>
        )
    } else {
      let tempBenefitOptions = [];
      benefit.options.map((bo) => tempBenefitOptions.push({ optionId: bo.optionId, selectionId: bo.selections ? bo.selections[0].selectionId : undefined}));
        return (
                <Button size="small" variant="contained" disabled={false} color="primary" onClick={(e) => { setSelectedBenefit(benefit); setSelectedBenefitOptions(tempBenefitOptions); setShowUpdateModal(true); }}>
                    Enable
                </Button>
        )
    }
  };

  const resetBenefits = () => {
    setSavedBenefits([]);
  };

  return (
    <Box position="relative">
        <Fragment>
          {isUpdatingSelections && (
            <Box className={classes.loadingOverlay}>
              <LogoSpinner size="large" />
            </Box>
          )}
          <Grid container direction="column">
            <Grid item>
              <Paper className={classes.moveGuideHeader}>
                <Box p={2.5}>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography variant="h6">Benefit Selections</Typography>
                        <Box sx={{ paddingTop: '0px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={applyTaxGrossUp}
                                        onChange={(e) => setApplyTaxGrossUp(!applyTaxGrossUp)}
                                        size="small"
                                    />
                                }
                                label={'Apply Tax Gross Up (60%)'}
                            />
                        </Box>
                      </Box>
                      
                    </Grid>
                    <Grid item xs={4} container justify="center">
                      <Box mt={1} width="100%" textAlign="center">
                        <SelectionsCostBar savedBenefits={savedBenefits} benefits={benefits} distance={props.distance} applyTaxGrossUp={applyTaxGrossUp} />
                      </Box>
                    </Grid>
                    <Grid item xs={4} container justify="flex-end" alignItems="flex-start">
                      <Box sx={{marginTop: '10px'}}>
                        <Button 
                          size="small"
                          color="secondary" 
                          className={classes.button} 
                          onClick={() => resetBenefits()}
                        >
                          Reset
                        </Button>
                        <Button variant="contained" 
                          size="small"
                          color="secondary" 
                          className={classes.button} 
                          onClick={() => props.exportEstimate(savedBenefits, benefits)}
                        >
                          Export
                        </Button>
                        <Button variant="contained" 
                          size="small"
                          color="secondary" 
                          className={classes.button} 
                          onClick={() => props.saveEstimate(savedBenefits, applyTaxGrossUp)}
                        >
                          Save
                        </Button>
                        <Button variant="contained" 
                          size="small"
                          color="primary" 
                          className={classes.longButton} 
                          onClick={() => props.generateAuth(savedBenefits, benefits, applyTaxGrossUp)}
                        >
                          Generate Auth
                        </Button>
                      </Box>
                      
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item className={classes.benefitListContainer}>
              <Grid container direction="column" wrap="nowrap">
                {
                  benefits.map((benefit) => {
                    let totalBenefitCost = 0;
                    let currentSavedBenefit = savedBenefits.find((sb) => sb.id === benefit.id);

                    if (benefit.customTotal) {
                      if (currentSavedBenefit) {
                        totalBenefitCost = currentSavedBenefit.cost;
                      } else {
                        totalBenefitCost = 0;
                      }
                    } else {
                      if (currentSavedBenefit) {
                        totalBenefitCost = benefit.options.filter((opt) => currentSavedBenefit.options.find((csbo) => csbo.optionId === opt.optionId)).reduce((total, o) => total + getOptionCost(o, currentSavedBenefit.options.find((csbo) => csbo.optionId === o.optionId)), 0);
                      } else {
                        totalBenefitCost = benefit.options.reduce((total, o) => total + (o.variable ? (props.distance * o.multiplier) : o.cost), 0);
                      }
                    }

                    if (props.reloPolicy && props.reloPolicy === 'Renter' && benefit.isOwnerOnly) return null;

                    if (props.reloPolicy && props.reloPolicy === 'Homeowner' && benefit.isRenterOnly) return null;

                    return (
                      <Accordion 
                        key={benefit.id}
                        expanded={expandedBenefitId === benefit.id}
                        className={expandedBenefitId === benefit.id ? classes.benefitSelected : null}
                        onChange={handleAccordionChange(benefit.id)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          // style={{ cursor: !hasSelectedOptions && 'default' }} // inline to ensure override
                        >
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1} style={{ backgroundImage: `url(${benefit.imageUrl})` }}
                              className={classNames(classes.benefitImage)}
                            />
                            <Grid item xs={11}>
                              <Box mr={0}>
                                <Grid container>
                                  <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                      {benefit.name}
                                    </Typography>
                                    <Box lineHeight="1">
                                      <Typography variant="caption">
                                        {benefit.secondaryText}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ paddingTop: '10px'}}>
                                        <Typography variant="subtitle1">{displayCost(totalBenefitCost, CostUnit.CURRENCY)}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={1}>
                                    {savedBenefits.find((sb) => sb.id === benefit.id) && (
                                        <Box sx={{ paddingTop: '15px' }}>
                                            <FontAwesomeIcon icon="check-circle" />
                                        </Box>
                                    )}
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ paddingTop: '10px'}}>
                                        {renderBenefitRowAction(benefit, savedBenefits.find((sb) => sb.id === benefit.id))}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginTop: 0 }}>
                          {
                            (benefit.options.length > 0) &&
                              <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                  <Box mr={4}>
                                    <Grid container direction="column" spacing={4}>
                                      {
                                        benefit.options.map((opt) => renderOption(opt, benefit, savedBenefits.find((sb) => sb.id === benefit.id), savedBenefits.find((sb) => sb.id === benefit.id) && savedBenefits.find((sb) => sb.id === benefit.id).options.find((o) => o.optionId === opt.optionId)))
                                      }
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                          }
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </Fragment> 

        {selectedBenefit && (
          <Dialog
            open={showUpdateModal}
            onClose={() => setShowUpdateModal(false)}
            maxWidth="md"
            fullWidth>
              <DialogTitle>
                {selectedBenefit.name}
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowUpdateModal(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  {selectedBenefit.options.map((opt) => {
                    let selectedOption = selectedBenefitOptions.find((sbo) => sbo.optionId === opt.optionId);
                    return (
                      <Box sx={{borderTop: '2px solid', backgroundColor: selectedOption ? '#f5f7f7' : undefined}}>
                        <Grid container>
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedOption}
                                  disabled={false}
                                  onChange={(e) => setBenOptionSelection(e.target.checked, opt)}
                                />
                              }
                              label={opt.description}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '5px', marginBottom: '5px'}}>
                              {opt.selections && (
                                  <TextField
                                      id="optionSelection"
                                      select
                                      label=""
                                      className={classes.textField}
                                      onChange={(e) => setBenOptionDropdown(opt, e.target.value)}
                                      value={selectedOption ? selectedOption.selectionId : 1}
                                      fullWidth={true}
                                      variant="outlined"
                                      size="small"
                                      disabled={!selectedOption}
                                  >
                                    {opt.selections.map((os) => <MenuItem value={os.selectionId} key={os.selectionId}>{os.text}</MenuItem>)}
                                  </TextField>
                              )}
                              {selectedBenefit.customTotal && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={customTotalIsTaxable}
                                      disabled={false}
                                      onChange={(e) => setCustomTotalIsTaxable(e.target.checked)}
                                    />
                                  }
                                  label={'Is Taxable'}
                                />
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            {!selectedBenefit.customTotal && (
                              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '8px'}}>
                                <Typography variant="subtitle1" color={selectedOption ? 'secondary' : undefined}>{displayCost(getOptionCost(opt, selectedOption), CostUnit.CURRENCY)}</Typography>
                              </Box>
                            )}
                            {selectedBenefit.customTotal && (
                              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '5px', paddingBottom: '5px'}}>
                                <TextField
                                    id="customTotal"
                                    label=""
                                    className={classes.textField}
                                    onChange={(e) => setCustomTotal(e.target.value)}
                                    value={customTotal}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    disabled={false}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', borderTop: '2px solid', paddingTop: '10px'}}>
                  <Box sx={{paddingRight: '10px'}}>
                    <Typography variant="subtitle1">Selected Total: </Typography>
                  </Box>
                  {!selectedBenefit.customTotal && (
                    <Typography variant="subtitle1" color="secondary">{displayCost(selectedBenefitOptions.reduce((total, o) => total + getOptionCost(selectedBenefit.options.find((sbo) => sbo.optionId === o.optionId), o), 0), CostUnit.CURRENCY)}</Typography>
                  )}
                  {selectedBenefit.customTotal && (
                    <Typography variant="subtitle1" color="secondary">{displayCost(Number(customTotal), CostUnit.CURRENCY)}</Typography>
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                {savedBenefits.find((sb) => sb.id === selectedBenefit.id && !isLoading) && (
                  <Grid container justify="flex-end">
                    <Box sx={{padding: '12px'}}>
                      <Button variant="outlined" size="large" color="primary" onClick={() => disableBenefit()} disabled={false}>Disable Benefit</Button>
                    </Box>
                    <Box sx={{padding: '12px'}}>
                      <Button variant="contained" size="large" color="primary" onClick={() => updateBenefit()} disabled={false}>Update Benefit</Button>
                    </Box>
                  </Grid>
                )}
                {!savedBenefits.find((sb) => sb.id === selectedBenefit.id && !isLoading) && (
                  <Grid container justify="flex-end">
                    <Box sx={{padding: '12px'}}>
                      <Button variant="contained" size="large" color="primary" onClick={() => enableBenefit()} disabled={false}>Enable Benefit</Button>
                    </Box>
                  </Grid>
                )}
                {isLoading && <LogoSpinner />}

              </DialogActions>
          </Dialog>
        )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    estimates: state.estimator.get('estimates'),
  };
};

export default 
  connect(mapStateToProps, {
    setEstimations
  })(BenefitSelections)
;
