import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { detectIE } from '../../../utilities/browserUtils';
import { emailTypeMap } from './emailTypeMap';
import { formatDateWithDashes } from '../../../utilities/dateFormatter';
import { get } from 'lodash';
import { isProposedEndDateRequired } from './utils';
import { phoneTypeMap } from './phoneTypeMap';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ConfirmModal from '../../../common/confirmModal.component';
import LogoSpinner from '../../../common/logoSpinner.component';
import NavigationPrompt from 'react-router-navigation-prompt';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SpinnerButton from '../../../common/spinnerButton.component';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import DuplicateAuthsModal from '../duplicateAuths/duplicateAuthsModal.component';
import AuthorizationModal from '../duplicateAuths/authorizationModal.component';

import {
  GET_BUS_CODES_SUCCESS,
  getAuthorizationCustomFields,
  getBusCodes,
  getClientFieldListValues,
  getClientSpecificFields,
  getCountries,
  getPendingAuthorization,
  getPendingAuthorizations,
  getReloPolicies,
  getStates,
  insertAuthorization,
  submitAuthorization,
  submitAuthorizationForApproval,
  getDuplicateAuths,
  updateAuthorization,
} from '../authorizations.actions';

import { getPolicies } from 'modules/policies/policy.actions';

import {
  TOAST_MESSAGE_SEVERITY_ERROR, 
  TOAST_MESSAGE_SEVERITY_SUCCESS, 
  setBackButtonPath,
  setNumPendingTransferees,
  setPageTitle,
  showToast,
} from '../../layout/layout.actions';

import {
  removeRecentItem,
  trackRecentItem,
} from '../../../utilities/trackRecents';
import {
  requiredField,
  validDate,
  validEmail,
  validPhoneNumber
} from '../../../utilities/fieldValidation';

import EmployeeForm from './employeeForm.component';
import NotesForm from './notesForm.component';
import SummaryForm from './summaryForm.component';

import {
  getSelectedClientInformation,
  getUserPermissionsList,
  userHasPermission,
  isAirBornClient,
  isKlaTaxClient, //Added via AB#869
  isBattelleEnergyAllianceClient, //Added via AB#869
} from '../../../utilities/authUtils';

import TransfereeModal from '../duplicateAuths/transfereeModal.component';

import { setBaseMoveInfo } from 'modules/transferees/transferees.actions';

const DIVISION_BUS_CODE_LIST = 'BC1';

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // marginTop: theme.spacing.unit * 3,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '2rem',
  },
  saveButton: {
    marginRight: '0.5rem',
  },
  navButtons: {
    height: '100%',
    marginLeft: 'auto',
  },
  paperSection: {
    width: '100%',
    padding: '1rem',
    marginBottom: '0.75rem',
  },
  submittedText: {
    color: 'green',
    fontSize: '1.25rem',
    display: 'inline',
    marginLeft: '0.25rem',
    verticalAlign: 'middle',
  },
  submittedIcon: {
    color: 'green',
    fontSize: '1.25rem',
    verticalAlign: 'middle',
  },
  cannotBeEdited: {
    width: '100%', 
    backgroundColor: 'yellow', 
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 'medium',
    padding: '10px',
  },
});
//Holds any errors returned by the API when auth is inserted/updated/submitted
let apiFormErrors = [];

class AuthorizationEditCreate extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);

    this.state = {
      authorizationID: -1,
      tabIndex: 0,
      dirty: false,
      isEdit: false,
      selectedAuth: {
        FirstName: props.baseMoveInfo?.FirstName ? props.baseMoveInfo.FirstName : '',
        LastName: props.baseMoveInfo?.LastName ? props.baseMoveInfo.LastName : '',
        SpouseFirstName: props.baseMoveInfo?.SpouseFirstName ? props.baseMoveInfo.SpouseFirstName : '',
        SpouseLastName: props.baseMoveInfo?.SpouseLastName ? props.baseMoveInfo.SpouseLastName : '',
        NickName: props.baseMoveInfo?.NickName ? props.baseMoveInfo.NickName : '',
        OldAddress1: props.baseMoveInfo?.OldAddress1 ? props.baseMoveInfo.OldAddress1 : '',
        OldAddress2: props.baseMoveInfo?.OldAddress2 ? props.baseMoveInfo.OldAddress2 : '',
        OldState: props.baseMoveInfo?.OldState ? props.baseMoveInfo.OldState : '',
        OldCity: props.baseMoveInfo?.OldCity ? props.baseMoveInfo.OldCity : '',
        OldZip: props.baseMoveInfo?.OldZip ? props.baseMoveInfo.OldZip : '',
        OldCountry: props.baseMoveInfo?.OldCountry ? props.baseMoveInfo.OldCountry : '',
        OldHomePhone: props.baseMoveInfo?.OldHomePhone ? props.baseMoveInfo.OldHomePhone : '',
        OldOfficePhone: props.baseMoveInfo?.OldOfficePhone ? props.baseMoveInfo.OldOfficePhone : '',
        OldMobile: props.baseMoveInfo?.OldMobile ? props.baseMoveInfo.OldMobile : '',
        OldEmail: props.baseMoveInfo?.OldEmail ? props.baseMoveInfo.OldEmail : '',
        SpouseEmail: props.baseMoveInfo?.SpouseEmail ? props.baseMoveInfo.SpouseEmail : '',
        AltEmail: props.baseMoveInfo?.AltEmail ? props.baseMoveInfo.AltEmail : '',
        NewAddress1: '',
        NewAddress2: '',
        NewState: urlParams.get('newState') ? urlParams.get('newState') : '',
        NewCity: urlParams.get('newCity') ? urlParams.get('newCity') : '',
        NewZip: '',
        NewCountry: urlParams.get('newCountry') ? urlParams.get('newCountry') : '',
        VIP: false,
        ProposedEffectiveDate: null,
        ProposedEndDate: null,
        EmployeeType: '',
        ReloPolicy: '',
        MoveType: '',
        Ownership: urlParams.get('ownership') ? urlParams.get('ownership') : '',
        ClientCustomFields: [],
        CustomFields: [],
        Comment: props.baseMoveInfo?.AuthId ? `This authorization was generated as a new move for the transferee ${props.baseMoveInfo?.FirstName} ${props.baseMoveInfo?.LastName} with ID ${props.baseMoveInfo?.AuthId}.` : '',
      },
      isLoadingAuth: false,
      authSubmitted: false,
      notesData: {
        notes: '',
      },
      isSubmitting: false,
      forceComponentRerender: Date.now(),
      duplicateAuths:{
        auths: [],
        lastName: '',
        helperText: undefined,
        selectedAuthorizationID: undefined,
        selectedMtID: undefined,
        displayAuthsTable: false
      },
      allValeroWorkLocationCodes: [],
    };


    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCustomFieldChange = this.handleCustomFieldChange.bind(this);
    this.handleClientCustomFieldChange = this.handleClientCustomFieldChange.bind(
      this,
    );
    this.handleContactChange = this.handleContactChange.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.handleFormSave = this.handleFormSave.bind(this);
    this.getFormErrors = this.getFormErrors.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSubmitForApproval = this.handleSubmitForApproval.bind(this);
    this.getDivisionBusCode = this.getDivisionBusCode.bind(this);
    this.startDateMustBeBeforeEndDate = this.startDateMustBeBeforeEndDate.bind(
      this,
    );
    this.endDateMustBeAfterStartDate = this.endDateMustBeAfterStartDate.bind(
      this,
    );
  }
  
  async componentDidMount() {
    this.props.setPageTitle(getSelectedClientInformation().id === '238'? 'MPC Authorizations' : 'My Authorizations');
    this.props.setBackButtonPath('/authorizations');
    this.setState({ isLoadingAuth: true });
    this.props.getCountries();
    this.props.getStates();
    await this.props.getAuthorizationCustomFields();
    await this.props.getClientSpecificFields({ AuthorizationID: null });
    await this.props.getClientFieldListValues();
    this.props.getReloPolicies();
    if (userHasPermission('selfService:policies', this.props.clientDivisions)) {
      this.props.getPolicies();
    }

    const selectedAuthId = this.props.authorizationID ? this.props.authorizationID : this.props.match.params.id;

    this.setState({authorizationID: !isNaN(selectedAuthId) ? selectedAuthId : -1});    

    if (selectedAuthId && selectedAuthId !== 'new') {
      let response = await this.props.getPendingAuthorization(
        selectedAuthId,
      );

      // check if we actually got an authorization.  if not, redirect to transferees
      if (
        response.response &&
        response.type === 'GET_PENDING_AUTHORIZATION_SUCCESS'
      ) {
        let name = '';
        let type = 'pendingAuthorization';
        let existingAuth = response.response;
        if (existingAuth) {
          const authClientId = String(existingAuth.ClientId);
          if (authClientId !== getSelectedClientInformation().id) {
            this.props.showToast('That authorization belongs to a different client. Please select the correct client to view it.', { severity: TOAST_MESSAGE_SEVERITY_ERROR } );
            this.props.history.push('/authorizations');
            return;
          }
          if (existingAuth.AuthorizationID) {
            name = `Pending Authorization #${  existingAuth.AuthorizationID}`;
          }

          if (existingAuth.Status !== 'Pending') {
            this.setState({ authSubmitted: true });
          }

          if (existingAuth.FirstName && existingAuth.LastName) {
            name = `${existingAuth.FirstName  } ${  existingAuth.LastName}`;
          }

          if (this.props.userInformation) {
            let UserName = this.props.userInformation.UserName;
            trackRecentItem(this.props.location.pathname, name, type, UserName);
          }

          // default values
          existingAuth.VIP = existingAuth.VIP ? existingAuth.VIP : false;
          existingAuth.OldCountry = existingAuth.OldCountry
            ? existingAuth.OldCountry
            : 'USA';
          existingAuth.NewCountry = existingAuth.NewCountry
            ? existingAuth.NewCountry
            : 'USA';
          existingAuth.SpouseEmail = existingAuth.SpouseEmail
            ? existingAuth.SpouseEmail
            : '';
          existingAuth.AltEmail = existingAuth.AltEmail
            ? existingAuth.AltEmail
            : '';

          // IE lacks support for date pickers, need to support multiple input methods
          // format date with dashes from input data
          if (!detectIE()) {
            existingAuth.ProposedEffectiveDate = existingAuth.ProposedEffectiveDate
              ? formatDateWithDashes(existingAuth.ProposedEffectiveDate)
              : '';
            existingAuth.ProposedEndDate = existingAuth.ProposedEndDate
              ? formatDateWithDashes(existingAuth.ProposedEndDate)
              : '';
          }
          if (detectIE()) {
            if (
              moment(
                existingAuth.ProposedEffectiveDate,
                'YYYY-MM-DDTHH:mm:ss',
                true,
              ).isValid()
            ) {
              existingAuth.ProposedEffectiveDate = formatDateWithDashes(
                existingAuth.ProposedEffectiveDate,
              );
            }

            if (
              moment(
                existingAuth.ProposedEndDate,
                'YYYY-MM-DDTHH:mm:ss',
                true,
              ).isValid()
            ) {
              existingAuth.ProposedEndDate = formatDateWithDashes(
                existingAuth.ProposedEndDate,
              );
            }
          }

          existingAuth.ClientCustomFields = this.prepareLinkedTextFields(
            existingAuth.ClientCustomFields,
            existingAuth.ReloPolicy,
          );
          
          this.setState({
            selectedAuth: existingAuth,
            isEdit: true,
            allValeroWorkLocationCodes: this?.props?.clientFields?.find(field => field.FieldName === "ValeroWorkLocationCode")?.FieldList || [],
          });

          //if this.props.readonly is set then that mean we're opening this from the authorizationModal and duplicateAuthsModal so we don't wanto to edit this
          if(this.props.readOnly) this.setState({ authSubmitted: true });
        }
      } else {
        // no auth returned
        this.props.showToast(response.messages, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        this.props.history.push('/authorizations');
      }
    }

    if (selectedAuthId === 'new') {
      // await this.props.getAuthorizationCustomFields();
      // await this.props.getClientSpecificFields({ AuthorizationID: null });
      let newAuth = this.state.selectedAuth;

      // default values
      newAuth.OldCountry = newAuth.OldCountry ? newAuth.OldCountry : 'USA';
      newAuth.NewCountry = newAuth.NewCountry ? newAuth.NewCountry : 'USA';

      await this.setState({
        isEdit: false,
        selectedAuth: {
          ...newAuth,
          ClientCustomFields: this.prepareLinkedTextFields(
            this.props.clientFields,
          ),
          CustomFields: this.props.authCustomFields,
        },
        allValeroWorkLocationCodes: this?.props?.clientFields?.find(field => field.FieldName === "ValeroWorkLocationCode")?.FieldList || [],
      });
    }

    const response = await this.props.getBusCodes();
    if (response.type === GET_BUS_CODES_SUCCESS){
      const moveTypes = response.response ? response.response.filter((item) => item.BusCodeType === 'MOVETYPE') : [];
      const defaultMoveType = moveTypes.find((mt) => String(mt.User9) === '1');
      if (!this.state.selectedAuth.MoveType && defaultMoveType){
        await this.setState({
          selectedAuth: {
            ...this.state.selectedAuth,
            MoveType: defaultMoveType.BusCode,
          },
        });
      }
    }

    this.setState({ isLoadingAuth: false });
  }

  handleTabChange = (event, value) => {
    this.setState({ tabIndex: value });
  };

  nextPage() {
    let { tabIndex } = this.state;
    if (tabIndex < 2) {
      this.setState({ tabIndex: tabIndex + 1 });
    }
  }

  prevPage() {
    let { tabIndex } = this.state;
    if (tabIndex > 0) {
      this.setState({ tabIndex: tabIndex - 1 });
    }
  }

  getDivisionBusCode() {
    if (this.state.selectedAuth) {
      const { CustomFields } = this.state.selectedAuth;
      if (CustomFields && CustomFields.length > 0) {
        let divCodes =
          !!this.props.busCodes &&
          this.props.busCodes.filter(
            (code) => code.BusCodeType === DIVISION_BUS_CODE_LIST,
          );
        return !!divCodes && divCodes.length > 0
          ? CustomFields.find((cf) => cf.BusCodeList === DIVISION_BUS_CODE_LIST)
          : null;
      }
      return null;
    }
  }

  getUserField(fieldName) {
    if (this.state.selectedAuth) {
      const { ClientCustomFields } = this.state.selectedAuth;
      if (ClientCustomFields && ClientCustomFields.length > 0) {
        const field = ClientCustomFields.find(
          (cf) => cf.FieldName === fieldName,
        );
        return field ? field.FieldValue : null;
      }
      return null;
    }
  }

  async handleFormSave(exit) {
    this.setState({ isSubmitting: true });

    let formData = this.state.selectedAuth;

    // add client id to save data
    let clientInfo = getSelectedClientInformation();
    if (clientInfo && clientInfo.id) {
      formData.ClientId = clientInfo.id;
    }

    // update
    if (this.props.match.params.id !== 'new') {
      let response = await this.props.updateAuthorization(formData);
      if (response.type === 'UPDATE_AUTH_SUCCESS') {
        this.props.showToast('Successfully saved authorization.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
        this.setState({ dirty: false });

        if (this.props.baseMoveInfo) {
          this.props.setBaseMoveInfo(null);
        }

        if (exit) {
          this.props.history.push('/authorizations');
        }
      }
      else if(response.hasErrors) {
        this.props.showToast(
          response.messages, { severity: TOAST_MESSAGE_SEVERITY_ERROR },
        );
      }
      else {
        this.props.showToast(
          'Error saving authorization. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR },
        );
      }
    } else {
      // new
      let response = await this.props.insertAuthorization(formData);
      // update count
      await this.props.getPendingAuthorizations();

      if (response.type === 'INSERT_AUTH_SUCCESS') {
        this.setState({ dirty: false });
        this.props.showToast('Successfully created authorization.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
        if (exit) {
          this.props.history.push('/authorizations');
        } else {
          this.props.history.push(`/authorizations/${response.response.AuthorizationID}`);
          await this.props.getPendingAuthorization(this.props.match.params.id);
          this.setState({ selectedAuth: this.props.selectedAuth });
          this.props.setNumPendingTransferees(
            this.props.pendingAuthorizations.length,
          );
        }
      }
      else if(response.hasErrors) {
        this.props.showToast(
          `${response.messages}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR },
        );
      } 
      else {
        this.props.showToast(
          'Error creating authorization. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR },
        );
      }
    }

    this.setState({ isSubmitting: false });
  }

  async handleFormSubmit() {
    this.setState({ isSubmitting: true });
    let auth = Object.assign({}, this.state.selectedAuth);

    // if home number is not provided, but other type is, copy other type into home number
    if (!auth.OldHomePhone) {
      auth.OldHomePhone = auth.OldOfficePhone || auth.OldMobile;
    }

    // if old email is not provided, but other type is, copy into old email
    if (!auth.OldEmail) {
      auth.OldEmail = auth.SpouseEmail ? auth.SpouseEmail : auth.AltEmail;
    }

    let { userInformation } = this.props;

    // add client contact to auth when submitting
    if (userInformation) {
      auth.ClientContact = userInformation.FullName;
    }

    // add client id to save data
    let clientInfo = getSelectedClientInformation();
    if (clientInfo && clientInfo.id) {
      auth.ClientId = clientInfo.id;
    }

    let response = await this.props.submitAuthorization(auth);
    if (response.type === 'SUBMIT_AUTH_SUCCESS') {
      this.props.showToast('Successfully submitted authorization.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });

      if (userInformation) {
        let UserName = this.props.userInformation.UserName;
        removeRecentItem(
          this.props.location.pathname,
          'pendingAuthorization',
          UserName,
        );
      }

      this.setState({ dirty: false, isSubmitting: false });
      this.props.history.push('/authorizations');
    }
    else if(response.formErrors) {
      this.setState({ isSubmitting: false });

      //map errors to apiFormErrors as strings so we can get them when getFormErrors() runs
      response.formErrors.forEach((error) => {        
        apiFormErrors.push(`${error.fieldLabel}: ${error.fieldMessage}`);
      });

      //change a state so the Summary component reloads
     this.setState({ forceComponentRerender: Date.now()});
      this.props.showToast(
        `${response.messages}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR },
      );
    } 
    else {
      this.setState({ isSubmitting: false });
      this.props.showToast(
        'Error submitting authorization. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR },
      );
    }
  }

  async handleSubmitForApproval() {
    this.setState({ isSubmitting: true });
    let auth = Object.assign({}, this.state.selectedAuth);

    // add client id to save data
    let clientInfo = getSelectedClientInformation();
    if (clientInfo && clientInfo.id) {
      auth.ClientId = clientInfo.id;
    }

    let response = await this.props.submitAuthorizationForApproval(auth);

    if (response.type === 'SUBMIT_AUTH_FOR_APPROVAL_SUCCESS') {
      this.props.showToast(
        'Successfully submitted authorization for approval', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS },
      );

      this.setState({ dirty: false, isSubmitting: false });
      this.props.history.push('/authorizations');
    } else {
      this.setState({ isSubmitting: false });
      this.props.showToast(
        'Error submitting authorization for approval. Please check your connection and try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR },
      );
    }
  }
  
  handleDuplicateAuthsModalClose = (event) => {
    this.setState({duplicateAuths: {...this.state.duplicateAuths, auths: []}});
  };

  handleDisplayAuthsModalClose = (event) => {
    this.setState({duplicateAuths: {...this.state.duplicateAuths, selectedAuthorizationID: undefined, selectedMtID: undefined}});
  };

  handleSetSelectedAuthorization = (authorizationID) => {    
    this.setState({duplicateAuths: {...this.state.duplicateAuths, selectedAuthorizationID: authorizationID}});
  }

  handleSetSelectedTransferee = (mtAuthId) => {
    this.setState({duplicateAuths: {...this.state.duplicateAuths, selectedMtID: mtAuthId}});
  }

  handleOnBlur = (name) => async (event) => {
    if (name === 'LastName'){
      //If MPC, return
      if(getSelectedClientInformation().id === '238') return;
      //make sure the value has actually changed before calling the API
      if(this.state.duplicateAuths.lastName === this.state.selectedAuth.LastName) return;

      //check to see if there is input
      if(this.state.selectedAuth.LastName.length === 0){
        this.setState({duplicateAuths: {...this.state.duplicateAuths, auths: [], lastName: this.state.selectedAuth.LastName, helperText: undefined}});   
        return;
      }

      this.setState({duplicateAuths: {...this.state.duplicateAuths, auths: [], lastName: this.state.selectedAuth.LastName, helperText: 'Checking for similar auths...'}});
      
      //call API 
      let response = await this.props.getDuplicateAuths({ clientID: getSelectedClientInformation().id, lastName: this.state.selectedAuth.LastName, authorizationID: this.state.authorizationID });

      if (response.type === 'GET_DUPLICATE_AUTHS_FAILURE') {
        this.setState({duplicateAuths: {...this.state.duplicateAuths, auths: [], lastName: this.state.selectedAuth.LastName, helperText: undefined}});        
        this.props.showToast(response.messages, { severity: TOAST_MESSAGE_SEVERITY_ERROR } );
        return;
      }

      if(response.response.length > 0) this.setState({duplicateAuths: {...this.state.duplicateAuths, auths: response.response, helperText: undefined, displayAuthsTable: true}});
      else this.setState({duplicateAuths: {...this.state.duplicateAuths, auths: [], lastName: this.state.selectedAuth.LastName, helperText: 'No similar auths found'}});
    }
  }

  handleFormFieldChange = (name) => (event) => {
    let updateValue = '';

    if (event != null && !event.target) {
      updateValue = event;
    } else if (event && event.target) {
      updateValue = event.target.value;
    }

    let updateSelectedAuth = {
      ...this.state.selectedAuth,
      [name]: updateValue,
    };

    // check if country was updated to be blank. if so, clear state as well
    if (name === 'OldCountry' && updateValue === '') {
      updateSelectedAuth['OldState'] = '';
    }

    if (name === 'NewCountry' && updateValue === '') {
      updateSelectedAuth['NewState'] = '';
    }

    if (name === 'ReloPolicy')
      updateSelectedAuth.ClientCustomFields = this.prepareLinkedTextFields(
        this.props.clientFields,
        updateSelectedAuth.ReloPolicy,
      );

   
    return new Promise((resolve) => {
      this.setState({
        selectedAuth: updateSelectedAuth,
        dirty: true,
      }, resolve);
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      selectedAuth: {
        ...this.state.selectedAuth,
        [name]: event.target.checked,
      },
      dirty: true,
    });
  };

  handleContactChange = (contactDetails) => {
    let updateSelectedAuth = Object.assign({}, this.state.selectedAuth);

    if (contactDetails.phoneDetails) {
      // clear existing values and replace with contactDetails
      phoneTypeMap.forEach((item) => {
        let foundDetail = contactDetails.phoneDetails.find(
          (detail) => detail.type === item.type,
        );
        if (foundDetail) {
          updateSelectedAuth[item.field] = foundDetail.number;
        } else {
          updateSelectedAuth[item.field] = '';
        }
      });
    }

    if (contactDetails.emailDetails) {
      // clear existing values and replace with contactDetails
      emailTypeMap.forEach((item) => {
        let foundDetail = contactDetails.emailDetails.find(
          (detail) => detail.type === item.type,
        );
        if (foundDetail) {
          updateSelectedAuth[item.field] = foundDetail.address;
        } else {
          updateSelectedAuth[item.field] = '';
        }
      });
    }

    this.setState({
      selectedAuth: updateSelectedAuth,
      dirty: true,
    });
  };

  // require end date if the MoveType is specified, and not permanent
  isEndDateRequired = (value) => {
    const { MoveType } = this.state.selectedAuth;
    if (!value && isProposedEndDateRequired(MoveType)) {
      return 'Required if Non-Permanent Move Type';
    }
    return null;
  };

  startDateMustBeBeforeEndDate = (value) => {
    let { ProposedEndDate } = this.state.selectedAuth;

    if (value && ProposedEndDate) {
      try {
        const startDate = new Date(value).getTime();
        const endDate = new Date(ProposedEndDate).getTime();
        return startDate < endDate ? null : 'Must be before Proposed End Date';
      } catch (e) {
        return null;
      }
    }
    return null;
  };

  endDateMustBeAfterStartDate = (value) => {
    let { ProposedEffectiveDate } = this.state.selectedAuth;

    if (value && ProposedEffectiveDate) {
      try {
        const endDate = new Date(value).getTime();
        const startDate = new Date(ProposedEffectiveDate).getTime();
        return endDate > startDate ? null : 'Must be after Effective Date';
      } catch (e) {
        return null;
      }
    }
    return null;
  };

  isSelfServicePolicyValid = (selfServicePolicies, reloPolicy, selectedPolicyId) => {
    const selectedPolicy = selfServicePolicies.find((pol) => pol.clientPolicyId === selectedPolicyId);
    if (!selectedPolicy) {
      return undefined;
    }
    let isSelectedPolicyValid = false;
    if (selectedPolicy.moveTrackPolicies.length) {
      isSelectedPolicyValid = selectedPolicy.moveTrackPolicies.some((mtPol) => mtPol.name === reloPolicy);
    }
    return isSelectedPolicyValid ? undefined : 'No longer valid';
  };

  // require province only for USA
  isProvinceRequired = (ISO3Code) => {
    return ISO3Code === 'USA';
  };

  getFormErrors() {
    let errors = [];
    const allowEmptyDestination = userHasPermission('authorization:allowEmptyDestination', this.props.clientDivisions);

    //check to see if errors came back from the API. If so, handle only those
    if(apiFormErrors.length > 0){
        errors = apiFormErrors; //assign apiFormErrors to errors since they are the same formaat
        apiFormErrors = []; //set back to empty array so it won't trigger again
        return errors;  //return just the errors from the API
    }

    let fieldValidations = [
      {
        name: 'FirstName',
        validators: [requiredField],
        friendlyName: 'First Name',
      },
      {
        name: 'LastName',
        validators: [requiredField],
        friendlyName: 'Last Name',
      },
      {
        name: 'OldAddress1',
        validators: (isKlaTaxClient() === true) ? [] : [requiredField], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Departure Address Line 1',
      },
      {
        name: 'OldCity',
        validators: (isKlaTaxClient() === true) ? [] : [requiredField], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Departure City',
      },
      {
        name: 'OldCountry',
        validators: (isKlaTaxClient() === true) ? [] : [requiredField], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Departure Country',
      },
      {
        name: 'OldEmail',
        validators: (isKlaTaxClient() === true) ? [] : [validEmail], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Work Email Address',
      },
      {
        name: 'SpouseEmail',
        validators: (isKlaTaxClient() === true) ? [] : [validEmail], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Spouse Email Address',
      },
      {
        name: 'AltEmail',
        validators: (isKlaTaxClient() === true) ? [] : [validEmail], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Personal Email Address',
      },
      {
        name: 'OldHomePhone',
        validators: (isKlaTaxClient() === true) ? [] : [validPhoneNumber], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Home Phone',
      },
      {
        name: 'OldOfficePhone',
        validators: (isKlaTaxClient() === true) ? [] : [validPhoneNumber], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Office Phone',
      },
      {
        name: 'OldMobile',
        validators: (isKlaTaxClient() === true) ? [] : [validPhoneNumber], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Mobile Phone',
      },
      {
        name: 'EmployeeType',
        validators: [requiredField],
        friendlyName: 'Employee Type',
      },
      {
        name: 'ReloPolicy',
        validators: [requiredField],
        friendlyName: 'Program Type',
      },
      {
        name: 'OldState',
        validators: [
          () => {
            return this.isProvinceRequired(
              this.state.selectedAuth.OldCountry,
            ) &&
              this.state.selectedAuth.OldCountry !== '' &&
              this.state.selectedAuth.OldState === '' &&
              isKlaTaxClient() !== true  //isKlaTaxClient() logic added via AB#869
              ? 'Required'
              : null;
          },
        ],
        friendlyName: 'Departure State',
      },
      {
        name: 'ProposedEndDate',
        validators: [
          validDate,
          this.isEndDateRequired,
          this.endDateMustBeAfterStartDate,
        ],
        friendlyName: 'Proposed End Date',
      },
      {
        name: 'ProposedEffectiveDate',
        validators: (isAirBornClient() === true) ? [requiredField, validDate, this.startDateMustBeBeforeEndDate] : [validDate, this.startDateMustBeBeforeEndDate],
        friendlyName: 'Effective Date',
      },
      {
        name: 'SelfServicePolicyId',
        validators: [
          (value) => this.isSelfServicePolicyValid(this.props.selfServicePolicies, this.state.selectedAuth.ReloPolicy, value),
        ],
        friendlyName: 'Self Service Policy',
      },
      {
        name: 'SelfServicePolicyBudget',
        validators: [
          (value) => {
            if (this.state.selectedAuth.SelfServicePolicyId) {
              return requiredField(value);
            }
            return undefined;
          },
        ],
        friendlyName: 'Self Service Policy Budget',
      },
    ];
  
    //if client ID is 238 (MPC) or 242 (Speedway), don't require the moveType field
    if(getSelectedClientInformation().id !== '238' && getSelectedClientInformation().id !== '242') {
      fieldValidations.push({
        name: 'MoveType',
        validators: [requiredField],
        friendlyName: 'Move Type'
      })
    }
  
   
    if (!allowEmptyDestination){
      fieldValidations.push({
        name: 'NewCity',
        validators: (isKlaTaxClient() === true) ? [] : [requiredField], //isKlaTaxClient() logic added via AB#869
        friendlyName: 'Destination City',
      });

      fieldValidations.push({
        name: 'NewState',
        validators: [
          () => {
            return (!allowEmptyDestination && 
              this.isProvinceRequired(
                this.state.selectedAuth.NewCountry,
              )) &&
              this.state.selectedAuth.NewCountry !== '' &&
              this.state.selectedAuth.NewState === '' &&
              isKlaTaxClient() !== true  //isKlaTaxClient() logic added via AB#869
              ? 'Required'
              : null;
          },
        ],
        friendlyName: 'Destination State',
      });
    }

    const phoneKeys = ['OldHomePhone', 'OldOfficePhone', 'OldMobile'];
    const emailKeys = ['OldEmail', 'SpouseEmail', 'AltEmail'];

    Object.keys(this.state.selectedAuth).forEach((key) => { 
      let foundKey = fieldValidations.find((item) => item.name === key);
      if (
        foundKey &&
        phoneKeys.indexOf(key) > -1 &&
        this.state.selectedAuth[key] !== '' &&
        this.state.selectedAuth[key] !== null
      ) {
        phoneKeys.splice(phoneKeys.indexOf(key), 1);
      } else if (
        foundKey &&
        phoneKeys.indexOf(key) > -1 &&
        isKlaTaxClient() === true  //isKlaTaxClient() logic added via AB#869
      ) {
        phoneKeys.splice(phoneKeys.indexOf(key), 1);
      }

      if (
        foundKey &&
        emailKeys.indexOf(key) > -1 &&
        this.state.selectedAuth[key] !== '' &&
        this.state.selectedAuth[key] !== null
      ) {
        emailKeys.splice(emailKeys.indexOf(key), 1);
      } else if (
        foundKey &&
        emailKeys.indexOf(key) > -1 &&
        isKlaTaxClient() === true  //isKlaTaxClient() logic added via AB#869
      ) {
        emailKeys.splice(emailKeys.indexOf(key), 1);
      }

      if (foundKey) {
        foundKey.validators.forEach((v) => {
          let error = v(this.state.selectedAuth[key]);
          if (error) {
            errors.push(`${foundKey.friendlyName  }: ${  error}`);
          }
        });
      }
    });

    // validate custom fields and client custom fields
    if (this.state.selectedAuth.ClientCustomFields) { 
      this.state.selectedAuth.ClientCustomFields.forEach((field) => {
        
        if (!field.FieldValue && field.FieldRequired) {
          errors.push(`${field.FieldLabel  }: Required`);
        }

        if (field.TextFormat === 'Date' && validDate(field.FieldValue)) {
          errors.push(`${field.FieldLabel  }: Invalid Date Format`);
        }

        if(field.FieldName === "ClientEmployeeID" && field.FieldValue && field.FieldValue.length > 25) errors.push(`${field.FieldLabel  }: Can't be longer than 25 characters`);
      });
    }

    if (this.state.selectedAuth.CustomFields) {
      this.state.selectedAuth.CustomFields.forEach((field) => {
        if (!field.BusCode && field.IsRequired) {
          errors.push(`${field.BusCodeLabel  }: Required`);
        }
      });
    }

    if (phoneKeys.length === 3) {
      errors.push('Phone Number: At least one is required');
    }

    if (emailKeys.length === emailTypeMap.length) {
      errors.push('Email Address: At least one is required');
    }

    return errors;
  }

  getLinkedTextFieldOption = (field) => {
    return (
      field.TextFormat === 'List' && field.FieldList.find((v) => v.includes('%'))
    );
  };

  getLinkedTextFieldOptionForKlaTaxAssignment = (field) => {
    return (
      field.TextFormat === 'List' && field.FieldList.find((v) => v.includes('%ChargeNum3%'))
    );
  };

  getLinkedTextFieldOptionForKlaTaxOther = (field) => {
    return (
      field.TextFormat === 'List' && field.FieldList.find((v) => (v.includes('%') && !v.includes('%ChargeNum3%')))
    );
  };

  getLinkedTextField = (listField, fields) => {
    if (!listField || listField.TextFormat !== 'List') return null;
    let linkedTextFieldOption = this.getLinkedTextFieldOption(listField);
    if (!linkedTextFieldOption) return null;
    return fields.find(
      (f) => f.FieldName === linkedTextFieldOption.replace(/%/g, ''),
    );
  };

  getLinkedTextFieldForKlaTaxAssignment = (listField, fields) => {
    if (!listField || listField.TextFormat !== 'List') return null;
    let linkedTextFieldOption = this.getLinkedTextFieldOptionForKlaTaxAssignment(listField);
    if (!linkedTextFieldOption) return null;
    return fields.find(
      (f) => f.FieldName === linkedTextFieldOption.replace(/%/g, ''),
    );
  };

  getLinkedTextFieldForKlaTaxOther = (listField, fields) => {
    if (!listField || listField.TextFormat !== 'List') return null;
    let linkedTextFieldOption = this.getLinkedTextFieldOptionForKlaTaxOther(listField);
    if (!linkedTextFieldOption) return null;
    return fields.find(
      (f) => f.FieldName === linkedTextFieldOption.replace(/%/g, ''),
    );
  };

  prepareLinkedTextFields = (fields, reloPolicy) => {

    const reloPolicyNameForCompare = !reloPolicy ? '' : reloPolicy.replace(/\s/g, '').replace(/[^a-z0-9]+/gi, '').toUpperCase();
    //reset fields not associated to current ReloPolicy
    fields.filter((f) => {
      if (!reloPolicy || reloPolicy.trim() === '') return false;

      return f.UserGroup === 'ReloPolicy' &&
        f.FieldName.toUpperCase() !== reloPolicyNameForCompare;
    },
    ).forEach((f) => {
      f.FieldValue = null;
    });

    const reloPolicyField = fields.find((f) => { 
      return f.UserGroup === 'ReloPolicy' && (!!reloPolicy && f.FieldName.toUpperCase() === reloPolicyNameForCompare);
    });

    // remove fields not linked to current ReloPolicy
    fields = fields.filter((f) => 
      f.UserGroup !== 'ReloPolicy' ||
      (reloPolicyField ? f === reloPolicyField : f.FieldName === 'NoPolicy'),
    );

    fields.forEach((field) => {
      if(isBattelleEnergyAllianceClient() === true) {
        // linked custom Charge Number "Assignment" field, for the "Battelle Energy Alliance" Client ONLY (via AB#869)
        const linkedTextFieldForAssignment = this.getLinkedTextFieldForKlaTaxAssignment(field, fields);
        if (linkedTextFieldForAssignment) {
          if (field.FieldValue !== this.getLinkedTextFieldOptionForKlaTaxAssignment(field)) {
            linkedTextFieldForAssignment.disabled = true;
            linkedTextFieldForAssignment.FieldValue = null;
            linkedTextFieldForAssignment.FieldRequired = false;
          } else {
            linkedTextFieldForAssignment.FieldRequired = true;
            linkedTextFieldForAssignment.disabled = false;
          }
        }
        // linked custom Charge Number "Other" field, for the "Battelle Energy Alliance" Client ONLY (via AB#869)
        const linkedTextFieldForOther = this.getLinkedTextFieldForKlaTaxOther(field, fields);
        if (linkedTextFieldForOther) {
          if (field.FieldValue !== this.getLinkedTextFieldOptionForKlaTaxOther(field)) {
            linkedTextFieldForOther.disabled = true;
            linkedTextFieldForOther.FieldValue = null;
            linkedTextFieldForOther.FieldRequired = false;
          } else {
            linkedTextFieldForOther.FieldRequired = true;
            linkedTextFieldForOther.disabled = false;
          }
        }
      } else {
        // linked "Other" fields
        const linkedTextField = this.getLinkedTextField(field, fields);
        if (linkedTextField) {
          if (field.FieldValue !== this.getLinkedTextFieldOption(field)) {
            linkedTextField.disabled = true;
            linkedTextField.FieldValue = null;
            linkedTextField.FieldRequired = false;
          } else {
            linkedTextField.FieldRequired = true;
            linkedTextField.disabled = false;
          }
        }
      }
    });

    //Added via AB#610 - For Handling H&P's (Helmerich and Payne) custom dropdown requirements and behavior.
    let areaIncentiveIndex = fields.findIndex(x => x.FieldName === 'AreaIncentive');
    let removeByDateIndex = fields.findIndex(x => x.FieldName === 'RemoveByDate');
    if(areaIncentiveIndex !== -1 && removeByDateIndex !== -1) {
      //ClientCustomField for 'AreaIncentive' was found; Handle the custom logic for the 'RemoveByDate' field, based upon the value of the 'AreaIncentive' field.
      if(fields[areaIncentiveIndex] && fields[areaIncentiveIndex].FieldValue === 'Yes') {
        fields[removeByDateIndex].disabled = false;
        fields[removeByDateIndex].FieldRequired = true;
        //fields[removeByDateIndex].FieldValue = null;
      } else {
        fields[removeByDateIndex].disabled = true;
        fields[removeByDateIndex].FieldRequired = false;
        fields[removeByDateIndex].FieldValue = null;
      }
    }

    return fields;
  };

  handleClientCustomFieldChange = (field) => (event) => {
    let allValeroWorkLocationCodes = [].concat(this.state.allValeroWorkLocationCodes);
    let updateFields = [].concat(this.state.selectedAuth.ClientCustomFields);
    // some controls send value directly, not wrapped in event
    let newValue = get(event, 'target.value', event);

    updateFields = updateFields.map((item) => {
      let foundUserField = field.UserFieldsId
        ? item.UserFieldsId === field.UserFieldsId
        : false;
      let foundPageField = field.PageFieldId
        ? item.PageFieldId === field.PageFieldId
        : false;

      if (foundUserField || foundPageField) {
        item.FieldValue = newValue;
      }

      //Added via AB#513 - For handling Valero's custom dropdown requirements and behavior.
      if(item && field && item.FieldName && field.FieldName && item.FieldName === 'ValeroWorkLocationCode' && field.FieldName === 'ValeroWorkLocationCity') {
        if(event === undefined || event === null) {
          item.FieldList = [].concat(allValeroWorkLocationCodes);
          item.FieldValue = null;
        } else {
          item.FieldList = allValeroWorkLocationCodes.filter(fieldListValue => fieldListValue?.toString()?.startsWith(event?.toString() + "|"));
        }
      }
      
      return item;
    });

    this.prepareLinkedTextFields(
      updateFields,
      this.state.selectedAuth.ReloPolicy,
    );

    this.setState({
      selectedAuth: {
        ...this.state.selectedAuth,
        ClientCustomFields: updateFields,
      },
      dirty: true,
    });
  };

  handleCustomFieldChange = (updatedCF) => {
    let updateFields = [].concat(this.state.selectedAuth.CustomFields);

    updateFields = updateFields.map((item) => {
      if (item.BusCodeList === updatedCF.BusCodeType) {
        item.BusCode = updatedCF.BusCode;
        item.BusCodeKey = updatedCF.BusCodeKey;
        item.BusCodeDesc = updatedCF.BusCodeDesc;
        item.User1 = updatedCF.User1;
        item.User2 = updatedCF.User2;
      }

      return item;
    });

    this.setState({
      selectedAuth: {
        ...this.state.selectedAuth,
        CustomFields: updateFields,
      },
      dirty: true,
    });
  };

  userIsApprover() {
    const clientInfo = getSelectedClientInformation();
    const clientId = clientInfo.id;
     
    const permissions = getUserPermissionsList();
    const approverPermission = permissions.find((p) => p.includes(`user:${clientId}:approver`));

    if (!approverPermission)
      return false;
    
    let permSplit = approverPermission.split(':');
    if (permSplit.length === 3)
      return true;
    
    if (permSplit.length !== 5)
      return false;
    
    const userFieldName = permSplit[3];
    const permUserFieldValue = permSplit[4];

    const userFieldValue = this.getUserField(userFieldName);

    return permUserFieldValue === userFieldValue;
  }

  clientRequiresApproval() {
    const { clientDivisions } = this.props;
    return  userHasPermission(
      'authorization:requiresApproval',
      clientDivisions,
    );
  }

  authWasSubmittedForApproval() {
    return this.state.selectedAuth.Approved !== null;
  }

  sumbitApproveButton(formHasErrors) {
    
    const {
      isSubmitting,
    } = this.state;

    let onClickHandler = this.handleFormSubmit;
    let buttonCaption = 'Submit';
    let buttonDisabled = false;

    const authWasSubmittedForApproval = this.authWasSubmittedForApproval();

    if (this.clientRequiresApproval()) {
      if (!this.userIsApprover()) {
        if (!authWasSubmittedForApproval) {
          onClickHandler = this.handleSubmitForApproval;
          buttonCaption = 'Submit For Approval';
        }
        else {
          onClickHandler = null;
          buttonCaption = 'Submitted for Approval';
          buttonDisabled = true;
        }
      }
      else {
        if (authWasSubmittedForApproval){
          buttonCaption = 'Approve';
        }
      }
    }

    return (
      <SpinnerButton
        isLoading={isSubmitting}
        buttonComponent={
          <Button
            variant="contained"
            color="primary"
            disabled={buttonDisabled || (formHasErrors || isSubmitting)}
            onClick={onClickHandler}
          >
            {buttonCaption}
          </Button>
        }
      />
    );
  }

  render() {
    const {
      classes,
      clientFieldValues,
      clientFields,
      authCustomFields,
      reloPolicies,
      selfServicePolicies,
      busCodes,
      drawerOpen,
    } = this.props;
    const {
      tabIndex,
      selectedAuth,
      isLoadingAuth,
      dirty,
      authSubmitted,
      isEdit,
      isSubmitting
    } = this.state;

    const formErrors = this.getFormErrors();
    const formHasErrors = formErrors.length > 0;

    const employeeFormData = {
      selectedAuth,
      clientFieldValues,
      clientFields,
      authCustomFields,
      reloPolicies,
      selfServicePolicies,
      busCodes,
    };

    console.log(getSelectedClientInformation());

    const divisionBusCode = this.getDivisionBusCode();
    let isSaveDisabled =
      divisionBusCode && !isEdit ? divisionBusCode.BusCode === null : false;

    if (isLoadingAuth) {
      return (
        <div style={{ margin: '2rem' }}>
          <LogoSpinner />
        </div>
      );
    } else {
      return (
        <div>
          <NavigationPrompt when={dirty}>
            {({ onConfirm, onCancel }) => {
              return (
                <ConfirmModal
                  cancelText="Cancel"
                  confirmText="Confirm"
                  handleClose={onCancel}
                  handleConfirm={onConfirm}
                  titleText="Unsaved Changes"
                  bodyText="You have unsaved changes on this authorization. Would you like to leave this page?"
                />
              );
            }}
          </NavigationPrompt>
         
         {(this.state.duplicateAuths.auths.length > 0 && !this.state.duplicateAuths.selectedAuthorizationID) &&                 
            <DuplicateAuthsModal
              // classes = {classes}
              handleClose={this.handleDuplicateAuthsModalClose}
              auths={this.state.duplicateAuths.auths}
              handleSetSelectedAuthorization = {this.handleSetSelectedAuthorization}
              handleSetSelectedTransferee = {this.handleSetSelectedTransferee}
              clientId={getSelectedClientInformation().id}
            />
         }

        {this.state.duplicateAuths.selectedAuthorizationID &&                 
            <AuthorizationModal            
              authorizationID = {this.state.duplicateAuths.selectedAuthorizationID}
              handleClose={this.handleDisplayAuthsModalClose}                          
            >
              </AuthorizationModal>
         }

        {this.state.duplicateAuths.selectedMtID &&                 
            <TransfereeModal            
              authorizationID={this.state.duplicateAuths.selectedMtID}
              handleClose={this.handleDisplayAuthsModalClose}                          
            />
         }


          <div className={classes.root}> 
            <AppBar position="static">
              <Tabs
                value={tabIndex}
                onChange={this.handleTabChange}
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  label={
                    <div style={{ display: 'inline' }}>
                      <div
                        style={{ verticalAlign: 'middle', display: 'inline' }}
                      >
                        Employee Information
                      </div>
                      {formHasErrors && (
                        <span>
                          <FontAwesomeIcon
                            icon="exclamation-triangle"
                            style={{
                              marginLeft: '0.5rem',
                              display: 'inline',
                              height: '15px',
                              verticalAlign: 'middle',
                            }}
                          />
                        </span>
                      )}
                    </div>
                  }
                />
                <Tab
                  disabled={!selectedAuth.AuthorizationID}
                  label="Documents / Notes"
                />
                <Tab disabled={!selectedAuth.AuthorizationID} label="Summary" />
              </Tabs>
            </AppBar>
            
            { (this.state.authSubmitted === true && !this.props.isModalRequest) &&   
              <div className={classes.cannotBeEdited}>This authorization has already be submitted and cannot be edited</div>
            }
            
            {tabIndex === 0 && (
              <TabContainer>
              <EmployeeForm
                  formData={employeeFormData}
                  isEdit={isEdit}
                  handleContactChange={this.handleContactChange}
                  handleFormChange={this.handleFormFieldChange}
                  handleOnBlur={this.handleOnBlur}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleCustomFieldChange={this.handleCustomFieldChange}
                  handleClientCustomFieldChange={this.handleClientCustomFieldChange}
                  readonly={authSubmitted}
                  duplicateAuthHelperText={this.state.duplicateAuths.helperText}
                />
              </TabContainer>
            )}
            {tabIndex === 1 && (
              <TabContainer>
                <NotesForm
                  formData={employeeFormData}
                  handleFormChange={this.handleFormFieldChange}
                  disabled={!selectedAuth.AuthorizationID}
                  readonly={authSubmitted}
                />
              </TabContainer>
            )}
            {tabIndex === 2 && (
              <TabContainer>
                <SummaryForm
                  formData={{ errors: formErrors }}
                  isSubmitted={authSubmitted}
                  authWasSubmittedForApproval={this.authWasSubmittedForApproval()}
                  clientRequiresApproval={this.clientRequiresApproval()}
                  userIsApprover={this.userIsApprover()}
                />
              </TabContainer>
            )}

            {!authSubmitted && (
              <div style={{ padding: '1rem', display: 'flex', marginLeft: drawerOpen ? 0 : 100 }}>
                <SpinnerButton
                  isLoading={isSubmitting}
                  buttonComponent={
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSaveDisabled || isSubmitting}
                      className={classes.saveButton}
                      onClick={() => this.handleFormSave(false)}
                    >
                      Save
                    </Button>
                  }
                />

                <SpinnerButton
                  isLoading={isSubmitting}
                  buttonComponent={
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSaveDisabled || isSubmitting}
                      className={classes.saveButton}
                      onClick={() => this.handleFormSave(true)}
                    >
                      Save and Exit
                    </Button>
                  }
                />

                <div className={classes.navButtons}>
                  {/* {tabIndex > 0 && selectedAuth.AuthorizationID &&
                  <Button variant="contained" color="primary" onClick={this.prevPage} style={{ marginRight: '0.25rem' }}>Previous</Button>
                } */}
                  {tabIndex !== 2 && selectedAuth.AuthorizationID && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={this.nextPage}
                    >
                      Next
                    </Button>
                  )}
                  {tabIndex === 2 && this.sumbitApproveButton(formHasErrors)}
                </div>
              </div>
            )}            
          </div>
        </div>
      );
    }
  }
}

AuthorizationEditCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};

AuthorizationEditCreate = withStyles(styles)(AuthorizationEditCreate);

const mapStateToProps = (state) => {
  return {
    selectedAuth: state.authorizations.get('pendingAuthorization'),
    countries: state.authorizations.get('countries'),
    clientFieldValues: state.authorizations.get('clientFieldValues'),
    clientFields: state.authorizations.get('clientFields'),
    authCustomFields: state.authorizations.get('authCustomFields'),
    reloPolicies: state.authorizations.get('reloPolicies'),
    selfServicePolicies: state.policy.policies,
    busCodes: state.authorizations.get('busCodes'),
    pendingAuthorizations: state.authorizations.get('pendingAuthorizations'),
    userInformation: state.layout.get('userInformation'),
    clientDivisions: state.layout.get('clientDivisions'),
    drawerOpen: state.layout.get('drawerOpen'),
    baseMoveInfo: state.transferees.get('baseMoveInfo'),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPendingAuthorization,
    getPendingAuthorizations,
    getCountries,
    getStates,
    getAuthorizationCustomFields,
    getClientFieldListValues,
    getClientSpecificFields,
    getReloPolicies,
    getPolicies,
    getBusCodes,
    updateAuthorization,
    showToast,
    insertAuthorization,
    submitAuthorization,
    submitAuthorizationForApproval,
    getDuplicateAuths,
    setPageTitle,
    setNumPendingTransferees,
    setBackButtonPath,
    setBaseMoveInfo,
  })(AuthorizationEditCreate),
);