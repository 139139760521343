import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import { clearBackButtonPath } from './layout.actions';
import { drawerWidth, drawerWidthClose } from './sidebarDrawer.component';
import { getSelectedClientInformation, getSelectedDivisions, logout } from '../../utilities/authUtils';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import BackArrow from '@material-ui/icons/ArrowBack';
import ChangePasswordDialog from './changePasswordDialog.component';
import ClientSelectDialog from './clientSelectDialog.component';
import DivisionSelectDialog from './divisionSelectDialog.component';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import reloSourceWhite from '../../images/ReloSource_wht.png';

import Badge from '@material-ui/core/Badge';
import FilterList from '@material-ui/icons/FilterList';

const styles = (theme) => ({
  flex: {
    flex: 1,
  },
  clientNameLabel: {
    fontSize: '1rem',
    display: 'inline',
  },
  appBar: {
    position: 'absolute',
    height: 56,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftClose: {
    marginLeft: drawerWidthClose,
    width: `calc(100% - ${drawerWidthClose}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  badge: {
    margin: 'auto',
    top: 0,
    height: 21,
    width: 21,
    right: -5,
  },
});

class DesktopAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      isShowClientSelect: false,
      isShowDivisionSelect: false,
      isShowChangePassword: false,
    };

    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.handleCloseClientSelect = this.handleCloseClientSelect.bind(this);
    this.handleShowClientSelect = this.handleShowClientSelect.bind(this);
    this.handleCloseDivisionSelect = this.handleCloseDivisionSelect.bind(this);
    this.handleShowDivisionSelect = this.handleShowDivisionSelect.bind(this);
    this.handleCloseChangePassword = this.handleCloseChangePassword.bind(this);
    this.handleShowChangePassword = this.handleShowChangePassword.bind(this);
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose() {
    this.setState({ anchorEl: null });
  }

  handleLogout() {
    logout();
  }

  handleShowClientSelect() {
    this.setState({
      anchorEl: null,
      isShowClientSelect: true,
    });
  }

  handleCloseClientSelect() {
    this.setState({
      isShowClientSelect: false,
    });
  }

  handleShowDivisionSelect() {
    this.setState({
      anchorEl: null,
      isShowDivisionSelect: true,
    });
  }

  handleCloseDivisionSelect() {
    this.setState({
      isShowDivisionSelect: false,
    });
  }

  handleShowChangePassword() {
    this.setState({
      isShowChangePassword: true,
    });
  }
  handleCloseChangePassword() {
    this.setState({
      isShowChangePassword: false,
    });
  }

  backNavigation = () => {
    if (this.props.backButtonPath) {
      this.props.history.push(this.props.backButtonPath);
    } else {
      this.props.history.push('/dashboard');
    }
    this.props.clearBackButtonPath();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, pageTitle, drawerOpen, clientOptions, divisionOptions } = this.props;
    const appBarMenuOpen = Boolean(anchorEl);
    const selectedClient = getSelectedClientInformation();
    const selectedDivisions = getSelectedDivisions();
    const useBackButton = !!this.props.backButtonPath;
    this.props.history.location.pathname === this.props.backButtonPath && this.props.clearBackButtonPath();

    const clientDisplayName = selectedClient.description;

    const isShowDivisionFilter = divisionOptions.length > selectedDivisions.length;

    return (
      <div>
        <AppBar className={classNames(classes.appBar, drawerOpen && classes.appBarShift, !drawerOpen && classes.appBarShiftClose)}>
          <Toolbar>
            {useBackButton &&
              <BackArrow style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={this.backNavigation} />
            }
            <Typography variant="h6" color="inherit" noWrap style={{ flex: '.5' }}>
              {pageTitle}
            </Typography>
            <div style={{ height: '50px', flex: '.5' }}>
              <img src={reloSourceWhite} alt="" style={{ maxHeight: '100%' }} />
            </div>
            <div>
              {clientDisplayName &&
                <Typography variant="h6" color="inherit" noWrap className={classes.clientNameLabel}>
                  {clientDisplayName}
                </Typography>
              }
              {isShowDivisionFilter &&
                <Badge classes={{ badge: classes.badge }} color="secondary" badgeContent={selectedDivisions.length}>
                  <IconButton
                    onClick={this.handleShowDivisionSelect}
                    color="inherit"
                  >
                    <FilterList />
                  </IconButton>
                </Badge>
              }
              <IconButton
                aria-owns={appBarMenuOpen ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenuOpen}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={appBarMenuOpen}
                onClose={this.handleMenuClose}
                MenuProps={{
                  PaperProps: {
                    style: {
                      zIndex: 9999, 
                    },
                  },
                }}
              >
                {clientOptions.length > 1 &&
                  <MenuItem onClick={this.handleShowClientSelect}>Change Client</MenuItem>
                }
                {divisionOptions.length > 1 &&
                  <MenuItem onClick={this.handleShowDivisionSelect}>Change Divisions</MenuItem>
                }
                {/* {showAdminMenu && <MenuItem onClick={this.handleUserAdmin}>User Administration</MenuItem>} */}
                <MenuItem onClick={this.handleShowChangePassword}>Change Password</MenuItem>
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                {/* <MenuItem onClick={this.handleChangePassword}>Change Password</MenuItem> */}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <ClientSelectDialog title={'Select Client'}
          open={this.state.isShowClientSelect}
          handleClose={this.handleCloseClientSelect}
          handleChangeClient={this.props.handleChangeClient}
          clientOptions={clientOptions}
          contentText={'You have access to more than one client. You may switch between clients.'}
          selectedClient={selectedClient ? selectedClient.id : null}
        />
        <DivisionSelectDialog title={'Select Divisions'}
          open={this.state.isShowDivisionSelect}
          handleClose={this.handleCloseDivisionSelect}
          handleChangeDivisions={this.props.handleChangeDivisions}
          divisionOptions={divisionOptions}
          contentText={'You have access to more than one division. You may choose to view one or more divisions. At least one is required.'}
          selectedDivisions={selectedDivisions ? selectedDivisions : null}
        />
        <ChangePasswordDialog title={'Change Password'}
          open={this.state.isShowChangePassword}
          handleClose={this.handleCloseChangePassword}
          contentText={'Your password will be reset and an email will be sent with instructions on how to change it.'}
        />
      </div>
    );
  }
}

DesktopAppBar = withStyles(styles, { withTheme: true })(DesktopAppBar);

const mapStateToProps = (state) => {
  return {
    pageTitle: state.layout.get('pageTitle'),
    drawerOpen: state.layout.get('drawerOpen'),
    backButtonPath: state.layout.get('backButtonPath'),
  };
};

export default withRouter(connect(mapStateToProps, {
  clearBackButtonPath,
})(DesktopAppBar));