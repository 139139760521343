import * as Sentry from '@sentry/react';
import * as firebase from 'firebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppRoutes from './app.routes';
import Callback from './authCallback';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import { CssBaseline, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import {
  getClientDivisions,
  setContentWidth,
  setMobileDrawerOpen,
  setUserInformation,
  showToast,
  TOAST_MESSAGE_SEVERITY_ERROR
} from './layout.actions';

import { buildIconLibrary } from './icons';
import { drawerWidth, drawerWidthClose } from './sidebarDrawer.component';
import ClientSelectDialog from './clientSelectDialog.component';
import DesktopAppBar from './desktopAppBar.component.js';
import DesktopDrawer from './desktopDrawer.component';
import DocumentTitle from 'react-document-title';
import DocumentTitleFormatter from '../../utilities/documentTitleFormatter';
import FullscreenSpinner from 'common/fullscreenSpinner.component';
import MapRouteTitle from '../../utilities/mapRouteTitle';
import MobileAppBar from './mobileAppBar.component';
import MobileDrawer from './mobileDrawer.component';
import Toast from './toast.container';

import {
  clearDivisionInformation,
  getParsedToken,
  getSelectedClientInformation,
  getUserClients,
  getUserDivisions,
  getUserMetadata,
  getUserName,
  isAuthenticated,
  isPathAuthorized,
  logout,
  setClientInformation,
  setSelectedDivisions,
  userHasPermission,
} from 'utilities/authUtils';

import { GET_FIREBASE_TOKEN_SUCCESS } from 'index.types';
import { clearRecents } from 'utilities/trackRecents';
import { getFirebaseToken } from 'index.actions';
import MaintenanceMonitor from 'modules/maintenanceMonitor/maintenanceMonitor.component';

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#15585e',
    },
    secondary: {
      main: '#4d8766',
    },
    action: {
      imageMaskOpacity: 0.3,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        outline: 'none',
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: '1.2rem',
      },
    },
  },
  props: {
    MuiGrid: {
      spacing: 2,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: 0,
    zIndex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100vh',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    minWidth: 300,
    overflow: 'auto',
    marginTop: 56,
  },
});

const REDIRECT_URL_KEY = 'redirectUrl';

class MainLayout extends PureComponent {
  constructor(props) {
    super(props);

    this.mainContentRef = null;
    this.drawerOpen = true;

    this.onRouteEnter = this.onRouteEnter.bind(this);
    this.handleMobileDrawerToggle = this.handleMobileDrawerToggle.bind(this);
    this.handleScrollTop = this.handleScrollTop.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.onDrawerOpen = this.onDrawerOpen.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleChangeClient = this.handleChangeClient.bind(this);
    this.handleChangeDivisions = this.handleChangeDivisions.bind(this);
  }

  async componentDidMount() {
    buildIconLibrary();
    if (isAuthenticated() && this.props.clientDivisions.length <= 0) {
      await this.props.getClientDivisions();
    }

    const auth0UserName = getUserName();
    const { fullName } = getUserMetadata();
    this.props.setUserInformation(auth0UserName, fullName);
    Sentry.setUser(getParsedToken());

    this.props.history.listen((location) => {
      this.handleScrollTop();
    });

    if (!isAuthenticated()) {
      this.props.auth.login();
    }

    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(this.handleResize, 50);
    });
    window.dispatchEvent(new CustomEvent('resize'));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.clientDivisions.length > 0 && prevProps.clientDivisions.length <= 0) {
      if (userHasPermission('selfService:benefits', this.props.clientDivisions)) {
        this.authenticateWithFirebase();
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  authenticateWithFirebase = async () => {
    try {
      const tokenResult = await this.props.getFirebaseToken();
      if (tokenResult.type === GET_FIREBASE_TOKEN_SUCCESS) {
        await firebase.auth().signInWithCustomToken(tokenResult.response);
      }
    } catch (e) {
      this.props.showToast('Failed to authenticate with firebase. Image loading may not work properly.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  };

  handleResize() {
    if (this.mainContentRef) {
      let width = window.innerWidth;
      this.props.setContentWidth(width);
      if (width < 960) {
        this.mainContentRef.style.marginLeft = '0px';
      } else if (width >= 960) {
        this.mainContentRef.style.marginLeft = this.drawerOpen ? `${drawerWidth  }px` : `${drawerWidthClose  }px`;
      }
    }
  }

  handleScrollTop() {
    if (this.mainContentRef) {
      this.mainContentRef.scrollTop = 0;
    }
  }

  onRouteEnter() {
    const authorized = isPathAuthorized(this.props.location.pathname, this.props.clientDivisions);
    const authenticated = isAuthenticated();

    if (!authenticated) {
      logout();
    }

    if (!authorized) {
      this.props.history.push('/');
    }
  }

  handleMobileDrawerToggle() {
    this.props.setMobileDrawerOpen(!this.props.mobileDrawerOpen);
  }

  onDrawerClose(mobile) {
    this.drawerOpen = false;
    if (!mobile) {
      this.mainContentRef.style.marginLeft = `${drawerWidthClose  }px`;
    }
    window.dispatchEvent(new CustomEvent('resize'));
  }

  onDrawerOpen(mobile) {
    this.drawerOpen = true;
    if (!mobile) {
      this.mainContentRef.style.marginLeft = `${drawerWidth  }px`;
    }
    window.dispatchEvent(new CustomEvent('resize'));
  }

  handleChangeClient(newClient) {
    clearDivisionInformation();
    let divisions = getUserDivisions(newClient.id, this.props.clientDivisions);
    if (divisions.length > 0) {
      setSelectedDivisions(divisions.map((d) => d.id));
    }
    clearRecents();
    setClientInformation(newClient);
    const redirectUrl = sessionStorage.getItem(REDIRECT_URL_KEY);
    if (redirectUrl) {
      window.location.href = redirectUrl;
      sessionStorage.removeItem(REDIRECT_URL_KEY);
    } else {
      window.location.href = '/';
    }
  }

  handleChangeDivisions(newDivisions) {
    setSelectedDivisions(newDivisions);
    clearRecents();
    // window.location.href = '/';
    window.location.reload();
  }

  render() {
    const { classes, clientDivisions, isLoading, isLoadingDivisions, showLoadingOverlay } = this.props;
    const isUserAuthenticated = isAuthenticated();
    const selectedClient = getSelectedClientInformation();

    const availableClients = getUserClients(clientDivisions);
    let availableDivisions = getUserDivisions(selectedClient.id ? selectedClient.id : null, clientDivisions);

    // filter out -1 options from available divisions - user cannot select -1
    availableDivisions = availableDivisions.filter((ad) => ad.id !== '-1')
      .sort((a, b) => a.id - b.id);

    if (!isUserAuthenticated) {
      this.props.auth.login();
      sessionStorage.setItem(REDIRECT_URL_KEY, this.props.location.pathname);
      return <Callback />;
    }

    if (isLoading || isLoadingDivisions) {
      return <Callback />;
    }

    // if user has more than 1 client available, prompt user to select one
    if (isUserAuthenticated && availableClients.length > 1 && !selectedClient.id) {
      return (<MuiThemeProvider theme={lightTheme}>
        <ClientSelectDialog title={'Select Client'}
          open={true}
          handleChangeClient={this.handleChangeClient}
          clientOptions={availableClients}
          showAsModal={true}
          contentText={'You have access to more than one client. Please select a client to continue.'}
        />
      </MuiThemeProvider>);
    }

    return (
      <div style={{ height: '100%' }}>
        {isUserAuthenticated &&
          <MuiThemeProvider theme={lightTheme}>
            {
              showLoadingOverlay &&
                  (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 3000,
                      backgroundColor: 'rgba(0,0,0,0.7)', // controls black overlay opacity
                      display: 'flex',
                    }}
                    >
                      <FullscreenSpinner size="large" />
                    </div>
                  )
            }
            <CssBaseline />

            <DocumentTitle title={DocumentTitleFormatter(MapRouteTitle(this.props.location.pathname))} >
              <div className={classes.root}>
                <div className={classes.appFrame}>
                  

                  <Hidden smDown>
                    <DesktopAppBar
                      clientOptions={availableClients}
                      handleChangeClient={this.handleChangeClient}
                      handleChangeDivisions={this.handleChangeDivisions}
                      divisionOptions={availableDivisions}
                    />
                  </Hidden>

                  <Hidden mdUp>
                    <MobileAppBar
                      handleMobileDrawerToggle={this.handleMobileDrawerToggle}
                      clientOptions={availableClients}
                      divisionOptions={availableDivisions}
                      handleChangeClient={this.handleChangeClient}
                      handleChangeDivisions={this.handleChangeDivisions}
                    />
                  </Hidden>

                  <Hidden smDown implementation="css">
                    <DesktopDrawer onDrawerClose={this.onDrawerClose} onDrawerOpen={this.onDrawerOpen} />
                  </Hidden>

                  <Hidden mdUp>
                    <MobileDrawer />
                  </Hidden>

                  <div className={classes.content} ref={(ref) => this.mainContentRef = ref}>
                    <MaintenanceMonitor />
                    <AppRoutes onRouteEnter={this.onRouteEnter} />
                    <Toast />
                  </div>

                </div>
              </div>
            </DocumentTitle>
          </MuiThemeProvider>
        }
      </div>
    );
  }
}

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  getFirebaseToken: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    clientDivisions: state.layout.get('clientDivisions'),
    isLoading: state.layout.get('isLoading'),
    isLoadingDivisions: state.layout.get('isLoadingDivisions'),
    showLoadingOverlay: state.layout.get('showLoadingOverlay'),
  };
};

MainLayout = withStyles(styles, { withTheme: true })(MainLayout);

export default withRouter(connect(mapStateToProps, {
  setMobileDrawerOpen,
  setContentWidth,
  setUserInformation,
  getClientDivisions,
  getFirebaseToken,
  showToast,
})(MainLayout));
