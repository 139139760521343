import React, { useEffect, useState } from "react";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { BASE_URL } from "middleware/api";
import { getAuthToken } from "utilities/authUtils";
import { Box, Modal, Typography } from "@material-ui/core";

const MaintenanceMonitor = () => {
  const [maintenanceTime, setMaintenanceTime] = useState(null);
  const [isSiteDown, setIsSiteDown] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    let authToken = getAuthToken();

    const connection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL}maintenanceHub`, {
        accessTokenFactory: () => authToken,
        transport:
          HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    connection.on("ReceiveMaintenanceStatus", (timestamp) => {
      if (timestamp) {
        const [year, month, day, hour, minute, second] = timestamp
          .split(/[- :T]/)
          .map(Number);
        const maintenanceDate = new Date(
          Date.UTC(year, month - 1, day, hour, minute, second || 0)
        );
        setMaintenanceTime(maintenanceDate);
      } else {
        setMaintenanceTime(null);
        setIsSiteDown(false);
        setWarningMessage("");
        setCountdown("");
      }
    });

    connection
      .start()
      .then(() => console.log("Connected to SignalR hub"))
      .catch((err) => console.error("SignalR connection error:", err));

    return () => {
      connection.stop();
    };
  }, []);

  useEffect(() => {
    if (!maintenanceTime) return;

    const interval = setInterval(() => {
      const utcNow = Date.now();
      const timeDifference = maintenanceTime - utcNow; // Difference in milliseconds
      if (timeDifference <= 0) {
        setIsSiteDown(true);
        setWarningMessage(
          "This site is down for emergency maintenance. \n Please check back in about 5 to 10 minutes to see if the site is back up."
        );
        setCountdown("");
      } else if (timeDifference <= 5 * 60 * 1000) {
        setIsSiteDown(false);
        setWarningMessage(
          "This site needs to go down for emergency maintenance in about 5 minutes. \n Please finish what you are currently doing, make sure all your work is saved, and then log out. \n You can check back in about 5 to 10 minutes to see if the site is back up."
        );

        const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);
        setCountdown(`${minutes}m ${seconds}s Remaining`);
      } else {
        setIsSiteDown(false);
        setWarningMessage("");
        setCountdown("");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [maintenanceTime]);

  const warningStyle = {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    padding: "15px",
    backgroundColor: "yellow",
    color: "black",
    fontSize: "1.25rem",
    fontWeight: "bold",
    zIndex: 1000,
    boxSizing: "border-box",
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    length: "1000px",
    padding: "50px",
    bgcolor: "white",
    border: "8px solid red",
    borderRadius: "10px",
    boxShadow: 24,
    textAlign: "center",
  };

  return (
    <>
      {warningMessage && !isSiteDown && (
        <Box
          style={warningStyle}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="10px"
        >
          <Typography variant="h6" style={{ flex: 1, textAlign: "center" }}>
            {warningMessage}
          </Typography>
          <Typography
            variant="h5"
            style={{
              flex: 0,
              textAlign: "center",
              fontWeight: "bold",
              marginLeft: "25px",
              marginRight: "15px",
            }}
          >
            {countdown}
          </Typography>
        </Box>
      )}
      <Modal open={isSiteDown} disableEscapeKeyDown disableBackdropClick>
        <Box sx={modalStyle}>
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "black" }}>
            {warningMessage}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default MaintenanceMonitor;
