import {
  faArchive,
  faArrowDown,
  faArrowUp,
  faBookOpen,
  faBoxOpen,
  faCalendarCheck,
  faCamera,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faCircle,
  faCog,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFile,
  faFileContract,
  faFileDownload,
  faFolder,
  faHandHoldingUsd,
  faHeadset,
  faHeart,
  faHistory,
  faHome,
  faHotel,
  faIdCard,
  faIndent,
  faListAlt,
  faListUl,
  faLocationArrow,
  faMale,
  faMapMarked,
  faMoneyBillAlt,
  faOutdent,
  faPaperclip,
  faPen,
  faPenSquare,
  faPlane,
  faPlaneDeparture,
  faPoll,
  faQuestionCircle,
  faRandom,
  faReceipt,
  faShip,
  faSign,
  faStar,
  faTachometerAlt,
  faThumbsUp,
  faTimesCircle,
  faTrash,
  faTruckMoving,
  faUndo,
  faUser,
  faUserPlus,
  faUsers,
  faWallet,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFacebookF,
  faGooglePlusG,
  faLinkedinIn,
  faTwitter,
  faWordpress,
} from '@fortawesome/free-brands-svg-icons';

import { faChartLine, faFileEdit,  faHouseLeave, faSackDollar, faUserChart, faUserFriends } from '@fortawesome/pro-solid-svg-icons';

export const buildIconLibrary = () => {
  library.add(
    faArchive,
    faBookOpen,
    faBoxOpen,
    faCalendarCheck,
    faCamera,
    faChartLine,
    faCircle,
    faCheck,
    faCheckSquare,
    faChevronDown,
    faCog,
    faDownload,
    faEdit,
    faEllipsisH,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFacebookF,
    faFile,
    faFileDownload,
    faFolder,
    faGooglePlusG,
    faHandHoldingUsd,
    faHeadset,
    faHeart,
    faHome,
    faHotel,
    faHouseLeave,
    faIdCard,
    faIndent,
    faLinkedinIn,
    faListAlt,
    faListUl,
    faLocationArrow,
    faMale,
    faMapMarked,
    faMoneyBillAlt,
    faOutdent,
    faPaperclip,
    faPen,
    faPenSquare,
    faPlane,
    faPlaneDeparture,
    faPoll,
    faQuestionCircle,
    faRandom,
    faReceipt,
    faSackDollar,
    faShip,
    faSign,
    faStar,
    faTachometerAlt,
    faThumbsUp,
    faTimesCircle,
    faTrash,
    faTruckMoving,
    faTwitter,
    faUndo,
    faUser,
    faUserChart,
    faUserFriends,
    faUserPlus,
    faUsers,
    faWallet,
    faWordpress,
    faHistory,
    faFileContract,
    faFileEdit,
    faDollarSign,
    faArrowDown,
    faArrowUp,
    faCheckCircle,
  );
};

export const LEGACY_ICON_MAP = Object.freeze({
  book: 'book-open',
  box: 'box-open',
  documents: 'file',
  driversLicense: 'id-card',
  folder: 'folder',
  gear: 'cog',
  group: 'users',
  headset: 'headset',
  hotel: 'hotel',
  house: 'home',
  houses: 'home',
  idCardSolid: 'id-card',
  mapMarked: 'map-marked',
  money: 'money-bill-alt',
  plane: 'plane-departure',
  receipt: 'receipt',
  receiptOutlined: 'receipt',
  timeline: 'calendar-check',
  truck: 'truck-moving',
  user: 'user',
  wallet: 'wallet',
});
